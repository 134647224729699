import { AxiosResponse } from 'axios';
import { parseString } from 'xml2js';

export const xmlParserMiddleware = <T extends string>(response: AxiosResponse<T>) => {
  if (response.headers['content-type'].includes('xml')) {
    parseString(response.data, (err, result) => {
      if (err) {
        throw err;
      }
      response.data = JSON.parse(result.string._);
    });
  }
  return response.data;
};
