import { PHONE_NUMBER_LENGTH } from '@config/constants';

export const validateEmail = (str: string) => {
  const message = 'Email is invalid';

  if (!str) {
    return undefined;
  }

  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    str.toLowerCase()
  )
    ? undefined
    : message;
};

export const validateInvalidCode = (value: string) => /^([0-9])\1{2}/.test(value);

export const validatePhoneNumber = (value: string) => {
  const message = 'Invalid phone number format';

  if (value.length < PHONE_NUMBER_LENGTH || validateInvalidCode(value)) {
    return message;
  }

  const regex = /^[0-9]+$/;

  return regex.test(value) ? undefined : message;
};

export const required = (value: string) => {
  return value.length ? undefined : 'Required';
};

export const isSoldOut = (invalidStart: number, invalidEnd: number) => {
  const myDate = new Date();
  const currentSeconds = myDate.getHours() * 3600 + myDate.getMinutes() * 60 + myDate.getSeconds();

  return currentSeconds > invalidStart && invalidStart < invalidEnd;
};
