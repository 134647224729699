import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { IGiftCard, IValidateGiftCard } from '@interfaces/GiftCard';
import memberApi from '@api/member.api';
import { memberIdSelector } from '@store/slices/auth/auth.selectors';
import { setCard } from '../order/order.slice';
// import { giftCardsSelector } from '@store/slices/member/member.selector';

export const getMemberGiftCards = createAsyncThunk<IGiftCard[]>(
  'member/getMemberGiftCards',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const memberId = memberIdSelector(state);

    if (!memberId) {
      throw new Error('No member id');
    }

    const { data, success } = await memberApi.getMemberGiftCards(memberId);

    if (!success) {
      throw new Error('Request failed');
    }

    return data;
  }
);

export const getMemberRewards = createAsyncThunk<{pointBalance: number; data: any[]}>(
  'member/getMemberRewards',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const memberId = memberIdSelector(state);

    if (!memberId) {
      throw new Error('No member id');
    }

    const { pointBalance, success, data } = await memberApi.getMemberRewards(memberId);

    if (!success) {
      throw new Error('Request failed');
    }

    return { pointBalance, data };
  }
);

export const validateGiftCard = createAsyncThunk<IGiftCard, IValidateGiftCard>(
  'order/validateGiftCard',
  async ({ pin, cardNumber }, { dispatch }) => {
    const { success, data } = await memberApi.validateGiftCard(cardNumber, pin);

    if (!success) {
      throw new Error('Card is not valid');
    }

    dispatch(setCard(data.cardNumber))

    return data;
  }
);
