import axios, { AxiosInstance, GenericAbortSignal } from 'axios';

import endpoints from '@config/endpoints';

import { IPaymentRequest, IPaymentProps } from '@interfaces/PaymentRequest';

const api = axios.create({
  baseURL: 'https://',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

class PaymentBaseApi {
  public api: AxiosInstance = api;
  public endpoints = endpoints.PAYMENT;
}

class PaymentApi extends PaymentBaseApi {
  async welcome({ apiToken, deviseId, posId, cloverIP }: IPaymentRequest) {
    const response = await this.api.post(
      `${cloverIP}${this.endpoints.WELCOME}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          'X-POS-Id': posId,
          'X-Clover-Device-Id': deviseId,
        },
      }
    );

    return response.data;
  }

  async ping({ apiToken, deviseId, posId, cloverIP }: IPaymentRequest) {
    const response = await this.api.post(
      `${cloverIP}${this.endpoints.PING}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          'X-POS-Id': posId,
          'X-Clover-Device-Id': deviseId,
        },
      }
    );

    return response.data;
  }

  async readTip(
    { apiToken, deviseId, posId, cloverIP, idempotencyKey }: IPaymentRequest,
    baseAmount: number,
    signal: GenericAbortSignal
  ) {
    const response = await this.api.post(
      `${cloverIP}${this.endpoints.READ_TIP}`,
      {
        baseAmount: Math.round(baseAmount * 100),
      },
      {
        signal,
        headers: {
          Authorization: `Bearer ${apiToken}`,
          'Idempotency-Key': idempotencyKey,
          'X-POS-Id': posId,
          'X-Clover-Device-Id': deviseId,
        },
      }
    );

    return response.data;
  }

  async salesPay(
    { apiToken, deviseId, posId, cloverIP, idempotencyKey }: IPaymentRequest,
    paymentProps: IPaymentProps,
    signal: GenericAbortSignal
  ) {
    const response = await this.api.post(
      `${cloverIP}${this.endpoints.PAYMENTS}`,
      {
        ...paymentProps,
        final: true,
      },
      {
        signal,
        headers: {
          Authorization: `Bearer ${apiToken}`,
          'Idempotency-Key': idempotencyKey,
          'X-POS-Id': posId,
          'X-Clover-Device-Id': deviseId,
        },
      }
    );

    const { payment } = response.data;

    if (payment.result !== 'SUCCESS') {
      return new Error('Failed to pay');
    }

    const paymentResponse = {
      Amount: payment['amount'],
      CardType: payment['cardTransaction']['cardType'],
      CardNo:
        payment['cardTransaction']['first6'] + '***********' + payment['cardTransaction']['last4'],
      EntryType: payment['cardTransaction']['entryType'],
      AuthCode: payment['cardTransaction']['authCode'],
      ReferenceId: payment['cardTransaction']['referenceId'],
      cloverPayId: payment['id'],
      credit_card_pay_amount: payment['amount'],
      credit_card_number:
        payment['cardTransaction']['first6'] + '***********' + payment['cardTransaction']['last4'],
      card_payments: [
        {
          amount: payment['amount'],
          tip: paymentProps.tipAmount,
          cloverPaymentID: payment['id'],
          CardType: payment['cardTransaction']['cardType'],
          cardNo:
            payment['cardTransaction']['first6'] +
            '***********' +
            payment['cardTransaction']['last4'],
          authCode: payment['cardTransaction']['authCode'],
          referenceId: payment['cardTransaction']['referenceId'],
        },
      ],
    };

    return paymentResponse;
  }

  async cancel({ apiToken, deviseId, posId, cloverIP }: IPaymentRequest) {
    const response = await this.api.post(
      `${cloverIP}${this.endpoints.CANCEL}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          'X-POS-Id': posId,
          'X-Clover-Device-Id': deviseId,
        },
      }
    );

    return response.data;
  }
}

export default new PaymentApi();
