import React from 'react';

import { ICategory } from '@interfaces/Category';
import styles from './CategoriesList.module.scss';

import CategoryItem from '../CategoryItem/CategoryItem';

type PropsType = {
  categories: ICategory[];
  categoryId: number;
  handleCategoryClick: (id: number) => void;
  handlePromotionClick: () => void;
};

const CategoriesList: React.FC<PropsType> = ({ categories, categoryId, handleCategoryClick }) => {
  return (
    <div className={styles.categories_container}>
      <span className={styles.heading}>Category</span>
      <div className={styles.categories_list}>
        <CategoryItem
          category={{
            id: 0,
            title: 'Promotions'
          }}
          categoryId={categoryId}
          onClick={handleCategoryClick}
          type='promotion'
        />
        {categories.map((item) => (
          <CategoryItem
            category={item}
            key={item.id}
            categoryId={categoryId}
            onClick={handleCategoryClick}
          />
        ))}
      </div>
    </div>
  );
};

export default CategoriesList;
