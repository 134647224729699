import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import routes from '@config/routes';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useStartOver } from '@hooks/useStartOver';
import { useSelectProductModal } from '@hooks/useSelectProductModal';
import {
  applyBonus,
  clearOrderPayment,
  removeBonus,
  removeCard,
  setCard,
  setTip,
  removeOrder,
  removeSoldOutItems,
  checkIfPromoIsAvailable,
} from '@store/slices/order/order.slice';
import { clearError, clearMember } from '@store/slices/member/member.slice';
import {
  allProductsRewardsId,
  appliedBonusesSelector,
  appliedBonusesValueSelector,
  cardPayValueSelector,
  currentPointsBalanceSelector,
  isPromoActiveSelector,
  orderProductsViewSelector,
  promotionsAmountValue,
  selectedGiftCardsSelector,
  subtotalSelector,
  subtotalWithBonusesSelector,
  taxesSelector,
  tipsSelector,
  tipValueSelector,
  totalSelector,
} from '@store/slices/order/order.selector';
import { shopProductsSelector } from '@store/slices/products/products.selectors';
import { isAuthorizedSelector } from '@store/slices/auth/auth.selectors';
import { giftCardsSelector, hasErrorGiftCardSelector } from '@store/slices/member/member.selector';
import {
  getMemberGiftCards,
  getMemberRewards,
  validateGiftCard,
} from '@store/slices/member/member.thunk';

import styles from './CheckOut.module.scss';

import {
  CheckOutLayout,
  Header,
  RightSection,
  LeftSection,
  SoldOutPopup,
} from '@components/CheckOut';
import { SelectProductModal } from '@components/Products';
import { IOrderView } from '@interfaces/Order';
import { isSoldOut } from '@utils/validators';
import { getRewardInformation } from '@store/slices/order/order.thunk';

const CheckOut = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const subtotalWithBonuses = useAppSelector(subtotalWithBonusesSelector);
  const subtotalWithoutBonuses = useAppSelector(subtotalSelector);
  const tipsAmount = useAppSelector(tipValueSelector);
  const taxes = useAppSelector(taxesSelector);
  const total = useAppSelector(totalSelector);
  const appliedBonuses = useAppSelector(appliedBonusesSelector);
  const hasErrorGiftCard = useAppSelector(hasErrorGiftCardSelector);
  const bonusValue = useAppSelector(appliedBonusesValueSelector);
  const giftCards = useAppSelector(giftCardsSelector);
  const selectedCards = useAppSelector(selectedGiftCardsSelector);
  const isAuthorized = useAppSelector(isAuthorizedSelector);
  const pointsBalance = useAppSelector(currentPointsBalanceSelector);
  const productsList = useAppSelector(orderProductsViewSelector);
  const cardPay = useAppSelector(cardPayValueSelector);
  const products = useAppSelector(shopProductsSelector);
  const tips = useAppSelector(tipsSelector);
  const rewardsId = useAppSelector(allProductsRewardsId);
  const promoAmountValue = useAppSelector(promotionsAmountValue)
  const isPromoActive = useAppSelector(isPromoActiveSelector)

  const [isOpenSoldOutModal, setIsOpenSoldOutModal] = useState(false);
  // const [isOpenToast, setIsOpenToast] = useState(false);
  const [productListSoldOut, setProductListSoldOut] = useState<IOrderView[]>([]);

  useEffect(() => {
    if (isAuthorized) {
      const fetch = async () => {
        await dispatch(getMemberRewards());
        await dispatch(getMemberGiftCards());
        await dispatch(getRewardInformation());
        await dispatch(checkIfPromoIsAvailable())
      };

      fetch();
    }
  }, []);

  const {
    props: selectProductModalProps,
    isOpen: isSelectProductModalOpen,
    openSelectProductModalInUpdateMode,
  } = useSelectProductModal({
    products,
  });

  const handleAddNewCard = (cardNumber: string, pin: string) => {
    const giftCardDuplication = !selectedCards.find((item) => item === cardNumber);
    if (giftCardDuplication) dispatch(validateGiftCard({ cardNumber, pin }));
  };

  const handleSetUsedCard = (cardId: string) => {
    dispatch(setCard(cardId));
  };

  const handleRemoveUsedCard = (cardId: string) => {
    dispatch(removeCard(cardId));
  };

  const handleChangeTips = (value: number) => {
    dispatch(setTip(value));
  };

  const handleUseBonuses = (orderId: string, value: number) => {
    dispatch(applyBonus({ orderId, value }));
  };

  const handleUnUseBonuses = (orderId: string) => {
    dispatch(removeBonus(orderId));
  };

  const handleBack = () => {
    navigate(routes.PRODUCT_LIST);
    dispatch(clearOrderPayment());
    dispatch(clearMember());
  };

  const handleRemoveOrder = (orderId: string) => {
    dispatch(removeOrder(orderId));
    dispatch(checkIfPromoIsAvailable())
  };

  const handleStartOver = useStartOver();

  const handleClearError = () => {
    dispatch(clearError());
  };

  const handlePay = () => {
    const soldOutProducts = productsList.filter((item) =>
      isSoldOut(item.invalid_start, item.invalid_end)
    );

    if (soldOutProducts.length !== 0) {
      setProductListSoldOut(soldOutProducts);
      setIsOpenSoldOutModal(true);
    } 
    navigate(routes.PAYMENT);
  };

  // const onCloseErrorModal = () => {
  //   setIsOpenToast(false);
  // };

  const handleLogin = () => {
    navigate({
      pathname: routes.SIGN_IN,
      search: '?tab=0',
    });
  };

  const onCloseSoldOutModal = () => {
    const soldOutIds = productListSoldOut.map((el) => el.id);
    dispatch(removeSoldOutItems(soldOutIds));
    setProductListSoldOut([]);
    setIsOpenSoldOutModal(false);
  };

  if (productsList.length === 0) {
    dispatch(clearOrderPayment());
    return <Navigate to={routes.PRODUCT_LIST} />;
  }

  return (
    <CheckOutLayout>
      <Header isAuthorized={isAuthorized} pointsBalance={pointsBalance} />
      <div className={styles.content}>
        {isSelectProductModalOpen && <SelectProductModal {...selectProductModalProps} />}
        <LeftSection
          productsList={productsList}
          productsRewardId={rewardsId}
          onUseBonuses={handleUseBonuses}
          onUnUseBonuses={handleUnUseBonuses}
          appliedBonuses={appliedBonuses}
          isAuthorized={isAuthorized}
          handleBack={handleBack}
          handleStartOver={handleStartOver}
          pointsBalance={pointsBalance}
          onRemoveOrder={handleRemoveOrder}
          onUpdateOrder={openSelectProductModalInUpdateMode}
          isPromoActive={isPromoActive}
          onLogin={handleLogin}
        />
        <RightSection
          tips={tips}
          maxTipAmount={subtotalWithoutBonuses}
          onChangeTip={handleChangeTips}
          hasErrorGiftCard={hasErrorGiftCard}
          subtotal={subtotalWithBonuses - promoAmountValue}
          isAuthorized={isAuthorized}
          giftCards={giftCards}
          onAddNewCard={handleAddNewCard}
          onUseGiftCard={handleSetUsedCard}
          onUnUseGiftCard={handleRemoveUsedCard}
          onPay={handlePay}
          selectedCardsIds={selectedCards}
          tipsAmount={tipsAmount}
          total={total - promoAmountValue}
          taxes={taxes}
          bonusValue={bonusValue}
          handleClearError={handleClearError}
          cardPay={cardPay}
        />
      </div>
      {/* {isOpenToast && <Toast title="Select tips before continuing" onClose={onCloseErrorModal} />} */}
      {isOpenSoldOutModal && (
        <SoldOutPopup items={productListSoldOut} onClose={onCloseSoldOutModal} />
      )}
    </CheckOutLayout>
  );
};

export default React.memo(CheckOut);
