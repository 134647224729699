import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '@store/store';

import rewardsApi from '@api/rewards.api';
import { rewardsSelector } from '../member/member.selector';
import { ordersSelector } from './order.selector';
import { shopSelector } from '../shop/shop.selectors';
import orderApi from '@api/order.api';
import { shopAllProductsSelector } from '../products/products.selectors';
import {  getProductsForPromotion } from './order.utils';

export const getRewardInformation = createAsyncThunk(
  'order/getRewardInformation',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const productsId = ordersSelector(state);
    const memberRewards = rewardsSelector(state);

    const requestData = {
      item_list: productsId.map((item) => item.productId),
      reward_list: memberRewards.map((item) => ({
        key_id: item.key,
        is_reward: 1,
      })),
    };

    const data = await rewardsApi.getInformation(requestData);

    return data;
  }
);

export const getShopPromotions = createAsyncThunk(
  'order/getShopPromotions',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const products = shopAllProductsSelector(state)
    const { id: shopId } = shopSelector(state);
    if (!shopId) throw Error()

    const result = await orderApi.getPromotions(+shopId)
    return {
      promotions: result,
      promotionsProducts: getProductsForPromotion(result, products),
    }
  }
);
