import { RootState } from '@store/store';
import { createSelector } from '@reduxjs/toolkit';
import {
  optionsSelector,
  productsOptionsSelector,
  shopProductsSelector,
  toppingsSelector,
} from '@store/slices/products/products.selectors';
import {
  calculateOrdersSubtotal,
  calculateOrdersSubtotalWithBonuses,
  getAllOrderProductsRewardId,
  getAppliedBonusesValue,
  getOrderProductsView,
  getOrderTotal,
  getProductsQuantityInTheCart,
  getPromotionsAmountValue,
  getTips,
  getTotalTaxes,
  getTotalValueUsedByCard,
} from '@store/slices/order/order.utils';
import { getPointBalanceAfterBonuses } from '@store/slices/order/order.utils';
import { giftCardsSelector, pointsBalanceSelector } from '@store/slices/member/member.selector';

export const promotionsProductsSelector = (state: RootState) =>
  state.order.promotions.promoProducts;

export const promotionsSelector = (state: RootState) => state.order.promotions.data;

export const availablePromoSelector = (state: RootState) => state.order.promotions.availablePromo

export const tipValueSelector = (state: RootState) => state.order.tip;

export const appliedBonusesSelector = (state: RootState) => state.order.appliedBonuses;

export const selectedGiftCardsSelector = (state: RootState) => state.order.selectedGiftCardsIds;

export const ordersSelector = (state: RootState) => state.order.orders;

export const tipsTemplateSelector = (state: RootState) => state.order.tipsTemplate;

export const ordersReward = (state: RootState) => state.order.ordersReward;

export const isPromoActiveSelector = (state: RootState) => state.order.promotions.promoIsActive

export const allProductsRewardsId = createSelector(ordersReward, getAllOrderProductsRewardId);

export const ordersCountSelector = createSelector(ordersSelector, getProductsQuantityInTheCart);

export const appliedBonusesValueSelector = createSelector(
  appliedBonusesSelector,
  getAppliedBonusesValue
);

export const currentPointsBalanceSelector = createSelector(
  pointsBalanceSelector,
  appliedBonusesValueSelector,
  getPointBalanceAfterBonuses
);

export const orderProductsViewSelector = createSelector(
  ordersSelector,
  shopProductsSelector,
  toppingsSelector,
  optionsSelector,
  productsOptionsSelector,
  getOrderProductsView
);

export const promotionsAmountValue = createSelector(
  orderProductsViewSelector,
  getPromotionsAmountValue
)

export const subtotalWithBonusesSelector = createSelector(
  orderProductsViewSelector,
  appliedBonusesSelector,
  calculateOrdersSubtotalWithBonuses
);

export const subtotalSelector = createSelector(orderProductsViewSelector, calculateOrdersSubtotal);

export const taxesSelector = createSelector(
  orderProductsViewSelector,
  appliedBonusesSelector,
  getTotalTaxes
);

export const cardPayValueSelector = createSelector(
  tipValueSelector,
  subtotalWithBonusesSelector,
  taxesSelector,
  selectedGiftCardsSelector,
  giftCardsSelector,
  promotionsAmountValue,
  getTotalValueUsedByCard
);

export const totalSelector = createSelector(
  tipValueSelector,
  subtotalWithBonusesSelector,
  taxesSelector,
  cardPayValueSelector,
  getOrderTotal
);

export const tipsSelector = createSelector(tipsTemplateSelector, subtotalSelector, getTips);
