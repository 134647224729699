import axios, { AxiosInstance, GenericAbortSignal } from 'axios';

import env from '@config/env';
import endpoints from '@config/endpoints';
import { IPromotion } from '@interfaces/Promotions';

const api = axios.create({
  baseURL: env.ENV === 'prod' ? env.ORDER_PROD_URL : env.ORDER_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${env.ORDER_TOKEN}`,
  },
});

class OrderBaseApi {
  public api: AxiosInstance = api;
  public endpoints = endpoints.ORDER;
}

class OrderApi extends OrderBaseApi {
    async createOrder(data: any, signal: GenericAbortSignal) {
        const response = await this.api.post(
            this.endpoints.CREATE_ORDER,
            data,
            {signal}
        )
        return response.data
    }

    async getPromotions(shopId: number): Promise<IPromotion[]> {
      const { data } =await this.api.post<IPromotion[]>(this.endpoints.PROMOTIONS, { shopId })
      return data
    }
}

export default new OrderApi();