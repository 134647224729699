import React from 'react';

import { BaseLayout } from '@components/Layout';

const NotFound = () => {
  return (
    <BaseLayout>
      <h1>404 Not Found</h1>
    </BaseLayout>
  );
};

export default NotFound;
