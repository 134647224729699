import { IOrder } from '@interfaces/Order';
import { IPaymentPayload, IPaymentProduct } from '@interfaces/Payment';
import { IApiProduct } from '@interfaces/ProductsResponse';
import { ProductVariantsEnum } from '@interfaces/enums';
import { getProductSizeFromVariant } from '@store/slices/products/products.utils';
import storage from '@config/storage';
import ls from '@utils/ls';

export const mapToPaymentProduct = (
  product: IApiProduct,
  variant: ProductVariantsEnum,
  order: any
): IPaymentProduct => {
  const {
    variantsIds,
    vat,
    price,
    description,
    description_cn,
    code,
    image_str,
    article_group,
    i_desc_cn,
    i_desc_en,
    invalid_start,
    invalid_end,
    variants,
    gid,
    id: productId
  } = product;
  const variantId = variantsIds[variant];
  const variantVat = vat[variant];
  const variantPrice = price[variant];
  const variantDescription = description[variant];
  const variantCode = code[variant];
  const isHot = variant === ProductVariantsEnum.Hot ? 1 : 0;
  const isDefault = variants.length === 1 ? 0 : variants.indexOf(variant);
  const size = getProductSizeFromVariant(variant);
  const toppingsList = order.toppings.map((topping: any) => {
    const findTopping = ls.get(storage.LS.TOPPINGS).find((item: any) => item.topping_id === topping.toppingId);
    return {
      article_id: findTopping.topping_id,
      description: findTopping.topping_description,
      qty: topping.quantity,
      price: findTopping.price.toString(),
      vat_percent: findTopping.vat.toString()
    }
  })

  const commentsIdArr = new Set(Object.values(order.options))
  const commentsList = [...commentsIdArr].map((id: any) => {
    const findComment = ls.get(storage.LS.OPTIONS).find((item: any) => item.comment_id === id)
    const groupComment = ls.get(storage.LS.PRODUCTS_OPTIONS);
    let findGroupComment;

    if (productId) {
      findGroupComment = groupComment[productId][variant].find((group: any) => {
        return group.items.find((item: number) => item === id)
      })
    }
    if (!findGroupComment) return {}
    return {
      comment_id: findComment.comment_id,
      group_description: findGroupComment.description,
      description: findComment.comment_description
    }
  })
  
  if (commentsList.length === 2) {
    commentsList.splice(0, 1)
  } else {
    commentsList.splice(0, 2)
  }

  if (
    variantVat === undefined ||
    variantPrice === undefined ||
    variantDescription === undefined ||
    variantCode === undefined ||
    variantId === undefined
  ) {
    throw new Error('Invalid variant');
  }

  const mapPromo = []
  
  if (order.promo) {
    for (let i = 0; i < order.promo?.quantity; i++) {
      mapPromo.push({
        key_id: `PROMOTION-${order.promo.id}`,
        is_reward: 1,
        is_whole_transaction: 0,
        discount_amount: order.promo.value,
        discount_type: order.promo.type
      })
    }
  }
  const paymentProduct: IPaymentProduct = {
    id: variantId,
    gid,
    vat: variantVat,
    price: variantPrice,
    description: description[ProductVariantsEnum.Default] || '',
    description_en: variantDescription,
    code: variantCode,
    description_cn,
    invalid_end,
    invalid_start,
    toppings: toppingsList,
    image_str: isDefault === 1 ? image_str : '',
    comments: commentsList,
    article_group,
    size,
    is_hot: isHot,
    is_default: Math.max(0, isDefault),
    promo: mapPromo
  };

  if (i_desc_cn) {
    paymentProduct.i_desc_cn = i_desc_cn;
  }

  if (i_desc_en) {
    paymentProduct.i_desc_en = i_desc_en;
  }

  return paymentProduct;
};
export const    preparePaymentPayload = (
  orders: IOrder[],
  rowProducts: IApiProduct[]
): IPaymentPayload => {
  return {
    items: orders.map((order) => {
      const product = rowProducts.find((p) => p.id === order.productId);

      if (!product) {
        throw new Error('Product not found');
      }

      const paymentProduct = mapToPaymentProduct(product, order.productVariant, order)
      const subTotal = order.quantity * paymentProduct.price;
      const vat = paymentProduct.vat /100 * subTotal;
      const total = subTotal + vat;
      return {
        id: paymentProduct.id,
        product: {
          id: paymentProduct.id,
          price: paymentProduct.price,
          vat: paymentProduct.vat
        },
        qty: order.quantity.toString(),
        total: total.toString(),
        subTotal: subTotal.toString(),
        vat: vat.toString(),
        toppingArray: paymentProduct.toppings.map((item: any) => ({
          id: item.article_id,
          subtotal: +item.price,
          qty: item.qty
        })),
        commentlevel: paymentProduct.comments.map(item => ({
          id: item.comment_id,
          title: item.group_description,
          description: item.description
        })),
        rewards: paymentProduct.promo.map((promo: any) => {
          const {key_id, discount_amount, is_reward, is_whole_transaction} = promo

          return {
            key_id,
            queue_id: null,
            discount_amount,
            is_reward,
            is_whole_transaction
          }
        })
      };
    }),
  };
};

export const prepareOrderPayload = (orders: IOrder[], rowProducts: IApiProduct[] | null, bonuses: any ) => {
  return orders.map((order) => {
    if (!rowProducts) {
      return
    }
    
    
    const product = rowProducts.find((p) => p.id === order.productId)
    let isReward = false;
    
    if (!product) {
      throw new Error('Product not found');
    }
    
    for (const bonuse in bonuses) {
      if (bonuse === order.id) {
        isReward = true;
      }
    }
    
    const orderProduct = mapToPaymentProduct(product, order.productVariant, order)
    return {
      article_id: orderProduct.id,
      qty: order.quantity,
      price: orderProduct.price.toString(),
      vat_percent: orderProduct.vat.toString(),
      instruction: '',
      description: orderProduct.description,
      size: orderProduct.size,
      total: ((orderProduct.vat / 100 * orderProduct.price) + orderProduct.price).toFixed(2),
      comments: orderProduct.comments,
      toppings: orderProduct.toppings,
      rewards: isReward
        ? [{
          discount_amount: orderProduct.price,
          is_reward: 1,
          is_whole_transaction: 0,
          key_id: "REWARD-36638:17963",
          queue_id: '36638'
        },]
        : [],
      promotions: orderProduct.promo
    }
  })
}