import React from 'react';

import styles from './CheckOutLayout.module.scss';

type PropsType = {
  children: React.ReactNode;
};

const CheckOutLayout: React.FC<PropsType> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

export default CheckOutLayout;
