import { createAsyncThunk } from '@reduxjs/toolkit';

import categoriesApi from '@api/categories.api';
import { ICategory } from '@interfaces/Category'
import { RootState } from '@store/store';
import { shopSelector } from '../shop/shop.selectors';

export const getCategories = createAsyncThunk<ICategory[]>(
    'categories/getCategories',
    async (_, {getState}) => {
        const state = getState() as RootState;
        const {id: shopID} = shopSelector(state);

        if (!shopID) {
            throw new Error('incorrect get products request')
        }

        return await categoriesApi.get(+shopID)
    }
)