import React from 'react';
import classNames from 'classnames';

import { ITab } from '@interfaces/Tabs';

import styles from './Tabs.module.scss';

type PropsType = {
  tabs: ITab[];
  setNewTab(tab: number): void;
  activeTab: number;
};

const Tabs: React.FC<PropsType> = ({ tabs, setNewTab, activeTab }) => {
  return (
    <ul className={styles.tabs}>
      {tabs.map(({ text }, index) => (
        <li
          key={index}
          className={classNames(index === activeTab && styles.active)}
          onClick={() => setNewTab(index)}
        >
          {text}
        </li>
      ))}
    </ul>
  );
};

export default React.memo(Tabs);
