import { OnlineOrderingBaseApi } from '@api/online-ordering-base.api';
import { IShopInfo, IValidateShopPayload } from '@interfaces/ShopInfo';

class StoreApi extends OnlineOrderingBaseApi {
  async verifyStore(data: IValidateShopPayload): Promise<IShopInfo> {
    return await this.api.post(this.endpoints.VERIFY_STORE, data);
  }
}

export default new StoreApi();
