import React from 'react';
import { IGiftCard } from '@interfaces/GiftCard';
import styles from './GiftCardsContainer.module.scss';
import { AddNewGiftCardForm, GiftCardsList } from '@components/CheckOut';

type PropsType = {
  giftCards: IGiftCard[];
  selectedCardsIds: string[];
  onAddNewCard(cardNumber: string, pin: string): void;
  onUseGiftCard(cardId: string): void;
  onUnUseGiftCard(cardId: string): void;
  hasErrorGiftCard: string;
  handleClearError(): void;
};

const GiftCardsContainer: React.FC<PropsType> = ({
  giftCards,
  onAddNewCard,
  onUseGiftCard,
  onUnUseGiftCard,
  selectedCardsIds,
  hasErrorGiftCard,
  handleClearError,
}) => {
  return (
    <div className={styles.container}>
      <AddNewGiftCardForm
        onAddNewCard={onAddNewCard}
        hasErrorGiftCard={hasErrorGiftCard}
        handleClearError={handleClearError}
      />
      {giftCards.length ? (
        <GiftCardsList
          selectedCardsIds={selectedCardsIds}
          giftCards={giftCards}
          onUnUseGiftCard={onUnUseGiftCard}
          onUseGiftCard={onUseGiftCard}
        />
      ) : null}
    </div>
  );
};

export default React.memo(GiftCardsContainer);
