import React from 'react';

import { PropsType as InputType } from '@components/Common/Input/Input';
import { PropsType as KeyboardType } from '@components/Common/Keyboard/Keyboard';
import styles from './KeyboardInput.module.scss';

import Input from '@components/Common/Input/Input';
import Keyboard from '@components/Common/Keyboard/Keyboard';

type PropsType = InputType & KeyboardType;

const KeyboardInput: React.FC<PropsType> = ({
  value,
  label,
  errorText,
  placeholder,
  type,
  invalid,
  onChangeKeyInput,
  inputName,
  onClickContinue,
}) => {
  return (
    <>
      <Input
        value={value}
        label={label}
        errorText={errorText}
        placeholder={placeholder}
        type={type}
        invalid={invalid}
        className={styles.input}
        name={inputName}
      />
      <Keyboard
        onChangeKeyInput={onChangeKeyInput}
        value={value}
        inputName={inputName}
        onClickContinue={onClickContinue}
      />
    </>
  );
};

export default React.memo(KeyboardInput);
