import { IProduct } from '@interfaces/Product';
import { createSlice } from '@reduxjs/toolkit';
import { getProductsData } from './products.thunk';
import { ITopping, IToppingGroup } from '@interfaces/Topping';
import {
  IProductOption,
  IProductOptionsGroup,
  ProductVariantsOptionsType,
} from '@interfaces/ProductOption';
import { customOptions, customOptionsGroups } from '@store/slices/products/products.data';
import { CustomOptionsEnum } from '@interfaces/enums';

interface IState {
  allProducts: IProduct[];
  products: Record<number, IProduct[]>;
  error: string;
  toppings: ITopping[];
  options: IProductOption[];
  customOptions: IProductOption[];
  customOptionsGroups: Record<CustomOptionsEnum, IProductOptionsGroup>;
  productsToppings: Record<number, IToppingGroup[]>;
  productsOptions: Record<number, ProductVariantsOptionsType>;
}

const initialState: IState = {
  allProducts: [],
  products: {},
  error: '',
  toppings: [],
  options: [],
  customOptions: customOptions,
  customOptionsGroups: customOptionsGroups,
  productsToppings: {},
  productsOptions: {},
};

const { reducer } = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProductsData.fulfilled, (state, { payload }) => {
      state.allProducts = payload.allProducts;
      state.products = payload.products;
      state.toppings = payload.toppings;
      state.productsToppings = payload.productsToppings;
      state.options = payload.options;
      state.productsOptions = payload.productsOptions;
    });

    builder.addCase(getProductsData.rejected, (state, action) => {
      // eslint-disable-next-line no-console
      console.log(action);
    });
  },
});

export default reducer;
