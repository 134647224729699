import { IProduct } from '@interfaces/Product';
import { FC, useState } from 'react';
import ProductItem from '../ProductItem/ProductItem';
import { Loader } from '@components/Common';

import ChevronLeft from '@assets/chevron-left.svg';

import styles from './PromotionsProductList.module.scss';
import { useAppSelector } from '@hooks/redux';
import { shopProductsSelector } from '@store/slices/products/products.selectors';
import { findKeyByItemId } from '@utils/findKeyByItemId';

interface PropsType {
  title: string;
  products: Record<string, IProduct[]>;
  onSelectProduct: (id: number, categoryId: number | undefined) => void;
  onClear: () => void;
}

const PromotionsProductList: FC<PropsType> = ({ products, title, onClear, onSelectProduct }) => {
  const productsData = useAppSelector(shopProductsSelector);
  const [index, setIndex] = useState(0);
  if (!products) return <Loader />;
  const handleIndexAdd = () => {
    if (Object.values(products).length - 1 === index) return;
    setIndex(index + 1);
  };
  const handleIndexRemove = () => {
    if (0 === index) return;
    setIndex(index - 1);
  };
  return (
    <div>
      <p className={styles.title}>
        <img src={ChevronLeft} alt="" onClick={onClear} />
        {title}
      </p>
      {Object.values(products).length > 1 ? (
        <div className={styles.pagination}>
          <button
            onClick={handleIndexRemove}
            className={`${styles.pagination_btn} ${index === 0 && styles.active}`}
          >
            Select 1st Item
          </button>
          <span className={styles.divider}></span>
          <button
            onClick={handleIndexAdd}
            className={`${styles.pagination_btn} ${index === 1 && styles.active}`}
          >
            Select 2nd Item
          </button>
        </div>
      ) : (
        ''
      )}
      <div className={styles.list}>
        {Object.values(products)[index].map((item) => (
          <ProductItem
            product={item}
            onSelectProduct={() =>
              onSelectProduct(item.id, findKeyByItemId(productsData, item.id))
            }
            key={item.id}
          />
        ))}
      </div>
    </div>
  );
};

export default PromotionsProductList;
