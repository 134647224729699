import React, { useEffect, useState } from 'react';

import lemonImg from '@assets/openmoji_lemon.png';

import styles from './AreYouHereModal.module.scss';

type PropsType = {
  onClose(): void;
};

const CHANGE_COUNTER_TIME = 1000;

const AreYouHereModal: React.FC<PropsType> = ({ onClose }) => {
  const [countdown, setCountdown] = useState(15);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, CHANGE_COUNTER_TIME);

    return () => {
      clearInterval(countdownInterval);
    };
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      onClose();
    }
  }, [countdown, onClose]);

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <h2>Are you still there?</h2>
        <div className={styles.lemonImage}>
          <img src={lemonImg} alt="lemon" />
          <p className={styles.counter}>{countdown}</p>
        </div>
      </div>
    </div>
  );
};

export default AreYouHereModal;
