import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { validateMemberId, signIn } from '@store/slices/auth/auth.thunk';

interface IState {
  isAuth: boolean;
  error: boolean;
  memberId: string;
  memberName: string;
  memberPhone: string;
}

const initialState: IState = {
  isAuth: false,
  error: false,
  memberId: '',
  memberName: '',
  memberPhone: '',
};

const { reducer, actions } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsError(state, { payload }: PayloadAction<boolean>) {
      state.error = payload;
    },
    clearAuth(state) {
      state.isAuth = initialState.isAuth;
      state.error = initialState.error;
      state.memberId = initialState.memberId;
      state.memberName = initialState.memberName;
      state.memberPhone = initialState.memberPhone;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      if (payload?.success) {
        state.error = false;
        state.memberId = payload.data;
        state.isAuth = true;
      } else {
        state.error = true;
        state.isAuth = false;
        state.memberId = '';
      }
    });
    builder.addCase(signIn.rejected, (state) => {
      state.error = true;
      state.isAuth = false;
      state.memberId = '';
    });
    builder.addCase(validateMemberId.fulfilled, (state, { payload }) => {
      state.memberId = payload.memberId;
      state.memberName = payload.firstName;
      state.memberPhone = payload.phoneCell;
      state.isAuth = true;
    });
    builder.addCase(validateMemberId.rejected, (state) => {
      state.memberId = '';
      state.isAuth = false;
      state.error = true;
    });
  },
});
export const { setIsError, clearAuth } = actions;

export default reducer;
