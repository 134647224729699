import { FC } from 'react';
import { IPromotion } from '@interfaces/Promotions';
import styles from './PromotionsList.module.scss';

import Promotion from '@assets/promo.png';

type PropsType = {
  promo: IPromotion[];
  onSelectPromo: (id: number, title: string) => void;
};

const PromotionsList: FC<PropsType> = ({ onSelectPromo, promo }) => {
  return (
    <div className={styles.wrapper}>
      {promo.map((item) => (
        <div
          className={styles.promotion}
          key={item.id}
          onClick={() => {
            onSelectPromo(item.id, item.description);
          }}
        >
          <img src={item.imageUrl} alt="" onError={(e) => e.currentTarget.src = Promotion}/>
          <p className={styles.promotion__title}>{item.description}</p>
        </div>
      ))}
    </div>
  );
};

export default PromotionsList;
