const number = parseInt((100 * Math.random()).toString())

function startTime() {
    var today = new Date();
	var year = today.getFullYear().toString();
    var h = checkTime(today.getHours()).toString();
    var m = checkTime(today.getMinutes()).toString();
    var s = checkTime(today.getSeconds()).toString();
    var showDateTime = year+h + m + s; 
    return showDateTime;	
}


function checkTime(i: any) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}

export default function () {
    return startTime() + number.toString();
} 