import React from 'react';

import styles from './DoYouWantToSignInModal.module.scss';
import { Button } from '@components/Common';

import loginIcon from '@assets/person.svg'
import lockIcon from '@assets/lock.svg'


type PropsType = {
  // onClose(): void;
  onLogin(): void;
  handleLoginAsGuest(): void;
};

const DoYouWantToSignInModal: React.FC<PropsType> = ({
  onLogin,
  handleLoginAsGuest,
}) => {

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <h1>The promotion has been applied!</h1>
        <h2>Sign in now to enjoy the savings.</h2>
        <Button id={styles.login} onClick={onLogin}>
         <img src={loginIcon} alt="" />
          Sign in
        </Button>
        <Button id={styles.loginAsGuest} variant="secondary" onClick={handleLoginAsGuest}>
          <img src={lockIcon} alt="" />
          Continue as a guest
        </Button>
      </div>
    </div>
  );
};

export default React.memo(DoYouWantToSignInModal);
