import { ICategory } from '@interfaces/Category';
import { OnlineOrderingBaseApi } from './online-ordering-base.api';

interface ICategoryResponce {
    id: number;
    description_cn: string;
    description_en: string;
}

class CategoriesApi extends OnlineOrderingBaseApi {
    async get(shopID: number): Promise<ICategory[]> {
        const { data } = await this.api.post<ICategoryResponce[]>(this.endpoints.CATEGORIES, {shopID});
        return this.mapResponce(data);
    }

    private mapResponce(data: ICategoryResponce[]): ICategory[] {
        return data.map(({id, description_en}) => ({
            id,
            title: description_en
        }))
    }
}

export default new CategoriesApi();