import React from 'react';
import styles from './PaymentFooter.module.scss';
import { Button } from '@components/Common';

type PropsType = {
  isPaymentInProgress?: boolean;
  isPaymentSuccess?: null | boolean;
  onBreak: () => void;
  onGoBack: () => void;
  onSuccsess: () => void;
};

const PaymentFooter: React.FC<PropsType> = ({ onBreak, isPaymentSuccess, onGoBack }) => {
  return (
    <div className={styles.container}>
      {isPaymentSuccess === null && (
        <>
          <Button className={styles.btn} onClick={onBreak}>
            Cancel Pay
          </Button>
        </>
      )}

      {isPaymentSuccess === false && (
        <Button className={styles.btn} onClick={onGoBack}>
          Go Back
        </Button>
      )}

      {isPaymentSuccess && (
        <Button className={styles.btn} onClick={onGoBack}>
          Start Over
        </Button>
      )}
    </div>
  );
};

export default React.memo(PaymentFooter);
