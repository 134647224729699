import React from 'react';
import { IGiftCard } from '@interfaces/GiftCard';
import styles from './GiftCardsList.module.scss';

import GiftCard from '@components/CheckOut/GiftCard/GiftCard';

type PropsType = {
  giftCards: IGiftCard[];
  selectedCardsIds: string[];
  onUseGiftCard(cardId: string): void;
  onUnUseGiftCard(cardId: string): void;
};

const GiftCardsList: React.FC<PropsType> = ({
  giftCards,
  selectedCardsIds,
  onUseGiftCard,
  onUnUseGiftCard,
}) => {
  return (
    <div className={styles.container}>
      <h1>Your gift Cards</h1>
      <div className={styles.giftCards}>
        {giftCards.map((card) => {
          const used = selectedCardsIds.includes(card.cardNumber);
          return (
            <GiftCard
              key={card.cardNumber}
              card={card}
              used={used}
              onUse={onUseGiftCard}
              onUnUse={onUnUseGiftCard}
            />
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(GiftCardsList);
