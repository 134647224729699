import { createAsyncThunk } from '@reduxjs/toolkit';

import storeApi from '@api/store.api';

import { IShopInfo, IValidateShopPayload } from '@interfaces/ShopInfo';
import {
  getShopDataFromLocalStorage,
  saveShopDataToLocalStorage,
} from '@store/slices/shop/shop.utils';
// import paymentApi from '@api/payment.api';

export const validateShop = createAsyncThunk<IShopInfo, IValidateShopPayload>(
  'shop/validateShop',
  async (payload: IValidateShopPayload) => {
    if (payload.shopID && payload.deviceID && payload.encryptionCode) {
      const data = await storeApi.verifyStore(payload);

      // const cloverPayCredentials = {
      //   apiToken: data.clover_api_token,
      //   deviseId: data.clover_device_id,
      //   posId: data.pos_id,
      //   cloverIP: data.clover_pay_url,
      // };

      // await fetch(`http://${data.upload_trans_url}/check_connect`, {
      //   method: 'POST',
      // }).then((data) => {
      //   return data.status;
      // });

      // await paymentApi.ping(cloverPayCredentials);

      saveShopDataToLocalStorage({
        ...data,
        deviceID: payload.deviceID,
        encryptionCode: payload.encryptionCode,
      });

      return data;
    }

    const shopInfoFromLS = getShopDataFromLocalStorage();

    if (!shopInfoFromLS) throw new Error('No shop info provided');

    // const cloverPayCredentials = {
    //   apiToken: shopInfoFromLS.clover_api_token,
    //   deviseId: shopInfoFromLS.clover_device_id,
    //   posId: shopInfoFromLS.pos_id,
    //   cloverIP: shopInfoFromLS.clover_pay_url,
    // };

    // await fetch(`http://${shopInfoFromLS.upload_trans_url}/check_connect`, {
    //   method: 'POST',
    // }).then((data) => {
    //   return data.status;
    // });

    // await paymentApi.ping(cloverPayCredentials);

    // if (shopInfoFromLS) {
    //   return shopInfoFromLS;
    // }

    throw new Error('No shop info provided');
  }
);
