import { ChangeEvent, useMemo, useState } from 'react';
import { debounce } from 'lodash';

import styles from './ScanModal.module.scss';

import crossIcon from '@assets/crossIcon.png';
import barcodeImg from '@assets/barcode.svg';

interface PropsType {
  onClose(): void;
  handleCheckBarcode(code: string): void;
}

const ScanModal: React.FC<PropsType> = ({ handleCheckBarcode, onClose }) => {
  const [barcode, setBarcode] = useState('');

  const handleCheckScannedCardDebounced = useMemo(() => {
    return debounce(handleCheckBarcode, 1000);
  }, []);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    handleCheckScannedCardDebounced(value);
    setBarcode(value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <button className={styles.close} onClick={onClose}>
          <img src={crossIcon} alt="close modal" />
        </button>
        <img src={barcodeImg} className={styles.modal__img} alt="barcode image" />
        <div className={styles.modal__input}>
          <label htmlFor="barcode">Scan Barcode</label>
          <input autoFocus type="password" id="barcode" onChange={onChange} value={barcode} />
        </div>
      </div>
    </div>
  );
};

export default ScanModal;
