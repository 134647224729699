import { IProduct } from '@interfaces/Product';
import { ITopping, IToppingGroupView } from '@interfaces/Topping';
import {
  IProductOption,
  IProductOptionsGroupView,
  ProductVariantsOptionsType,
  ProductVariantsOptionsViewType,
} from '@interfaces/ProductOption';
import { IOrder } from '@interfaces/Order';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { addOrder, removeBonus, updateOrder } from '@store/slices/order/order.slice';
import {
  optionsSelector,
  productsCustomOptionsViewSelector,
  productsOptionsSelector,
  productsOptionsViewSelector,
  productsToppingsViewSelector,
  toppingsSelector,
} from '@store/slices/products/products.selectors';
import { ordersSelector } from '@store/slices/order/order.selector';

interface IRowData {
  toppings: ITopping[];
  options: IProductOption[];
  productOptions: Record<number, ProductVariantsOptionsType>;
}

export type SelectProductModalPropsType = {
  categoryId?: number;
  product: IProduct | null;
  toppings?: IToppingGroupView[];
  options?: ProductVariantsOptionsViewType;
  customOptions?: IProductOptionsGroupView[];
  onClose?: () => void;
  onAddOrder?: (order: IOrder) => void;
  onUpdateOrder?: (order: IOrder) => void;
  rowData: IRowData;
  initialOrder?: IOrder | null;
  btnLabel?: string;
};

export type ModeType = 'add' | 'update';

interface IParams {
  categoryId?: number;
  products: Record<number, IProduct[]>;
  onClose?: (mode: ModeType) => void;
}

interface IReturn {
  props: SelectProductModalPropsType;
  isOpen: boolean;
  openSelectProductModalInAddMode: (productId: number) => void;
  openSelectProductModalInUpdateMode: (orderId: string) => void;
  mode: ModeType;
}

export const useSelectProductModal = ({ categoryId, products, onClose }: IParams): IReturn => {
  const toppings = useAppSelector(productsToppingsViewSelector);
  const options = useAppSelector(productsOptionsViewSelector);
  const customOptions = useAppSelector(productsCustomOptionsViewSelector);
  const rawToppings = useAppSelector(toppingsSelector);
  const rawOptions = useAppSelector(optionsSelector);
  const rawProductOptions = useAppSelector(productsOptionsSelector);
  const orders = useAppSelector(ordersSelector);

  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);
  const [selectedOrder, setSelectedOrder] = useState<IOrder | null>(null);
  const [categoryIdFromPromo, setCategoryIdFromPromo] = useState<number | undefined>()

  const dispatch = useAppDispatch();

  const mode: ModeType = selectedOrder ? 'update' : 'add';

  const selectProduct = (id: number, category: number) => {
    const product = products[category].find((p) => p.id === id);

    if (product) {
      setSelectedProduct(product);
    }
  };

  const closeSelectProductModal = () => {
    setSelectedProduct(null);

    if (selectedOrder) {
      setSelectedOrder(null);
    }

    if (onClose) {
      onClose(mode);
    }
  };

  const onAddOrder = (order: IOrder) => {
    dispatch(addOrder(order));
    closeSelectProductModal();
  };

  const onUpdateOrder = (order: IOrder) => {
    dispatch(updateOrder(order));
    dispatch(removeBonus(order.id));
    closeSelectProductModal();
  };

  const handleSelectProduct = (id: number, categoryFromPromo?: number | undefined) => {
    if (categoryId) {
      selectProduct(id, categoryId);
    }

    if (categoryFromPromo) {
      selectProduct(id, categoryFromPromo)
      setCategoryIdFromPromo(categoryFromPromo)
    }
  };

  const handleUpdatesOrder = (orderId: string) => {
    const order = orders.find((o) => o.id === orderId);

    if (order) {
      setSelectedOrder(order);
      selectProduct(order.productId, order.categoryId);
    }
  };

  return {
    isOpen: !!(selectedProduct || (selectedOrder && selectedProduct)),
    mode,
    openSelectProductModalInAddMode: handleSelectProduct,
    openSelectProductModalInUpdateMode: handleUpdatesOrder,
    props: {
      categoryId: categoryId || categoryIdFromPromo,
      product: selectedProduct,
      initialOrder: selectedOrder,
      btnLabel: selectedOrder ? 'Update' : 'Add',
      toppings: selectedProduct ? toppings[selectedProduct.id] : [],
      options: selectedProduct ? options[selectedProduct.id] : {},
      customOptions: selectedProduct ? customOptions[selectedProduct.id] : [],
      onAddOrder,
      onClose: closeSelectProductModal,
      onUpdateOrder,
      rowData: {
        productOptions: rawProductOptions,
        options: rawOptions,
        toppings: rawToppings,
      },
    },
  };
};
