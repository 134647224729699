export default {
  ONLINE: {
    VERIFY_STORE: '/VerifyStore',
    CATEGORIES: '/getArticleGroups',
    PRODUCTION: '/getOnlineOrderProduction',
    REWARD_INFORMATION: '/getRewardInformation',
    PAY_BY_GIFT_CARD: '/payByCard'
  },
  MEMBERSHIP: {
    SIGN_IN_BY_PASSWORD: '/api/v2/authenticate/member-pwd',
    MEMBER: '/api/v2/loyalty/member/',
    GIFT_CARD: '/api/v2/giftcard/',
  },
  PAYMENT: {
    WELCOME: '/connect/v1/device/welcome',
    CANCEL: '/connect/v1/device/cancel',
    READ_TIP: '/connect/v1/device/read-tip',
    PAYMENTS: '/connect/v1/payments',
    PING: '/connect/v1/device/ping'
  },
  TSPOS: {
    UPLOAD: '/upload_trans/',
    CHECK_CONNECT: '/check_connect',
  },
  ORDER: {
    CREATE_ORDER: '/api/SelfOrder/CreateOrder',
    PROMOTIONS: '/api/SbmOrder/GetPromotions',
  }
};
