import { v4 as uuidv4 } from 'uuid';
import {
  IApiProduct,
  IApiTopping,
  IArticleComment,
  IArticleToppingInner,
  IComment,
  ICommentItem,
  IInteractionProductGroup,
  IVariantProduct,
  IProductApiResponse,
  IProductResponse,
  IProductFromBarcodeApiResponse,
} from '@interfaces/ProductsResponse';
import { OnlineOrderingBaseApi } from './online-ordering-base.api';
import { IProductsRequest } from '@interfaces/ProductsRequest';
import { IToppingGroup } from '@interfaces/Topping';
import { ProductTypeEnum } from '@interfaces/Product';
import { IProductOptionsGroup, ProductVariantsOptionsType } from '@interfaces/ProductOption';
import { productSizes, ProductTemperatureEnum, ProductVariantsEnum } from '@interfaces/enums';
import { getProductVariantFromSize } from '@store/slices/products/products.utils';

class ProductsApi extends OnlineOrderingBaseApi {
  async get({ shopID, regionID, pricelevelID }: IProductsRequest): Promise<IProductApiResponse> {
    const { data } = await this.api.post<IProductResponse>(this.endpoints.PRODUCTION, {
      shopID,
      regionID,
      pricelevelID,
    });

    const products = this.getAllProducts(data);
    const toppings = this.getAllToppings(data);
    const products_toppings = this.getProductsToppings(data, products);
    const options = this.getAllOptions(data);
    const products_options = this.getProductsOptions(data, products);
    return {
      products,
      toppings,
      products_toppings,
      options,
      products_options,
    };
  }

  async getProductFromBarcode({
    barcode,
    url,
  }: {
    barcode: string;
    url: string;
  }): Promise<IProductFromBarcodeApiResponse> {
    const product = await fetch(`http://${url}/scanArticleBarcode`, {
      method: 'POST',
      body: JSON.stringify({ barcode }),
    }).then((data) => data.json());

    return product;
  }

  private getAllProducts(data: IProductResponse): IApiProduct[] {
    const products: IApiProduct[] = [];

    data.group_list.forEach((item) => {
      item.production_list.article_list.forEach((defaultProduct) => {
        const product = this.getBaseProduct(
          defaultProduct,
          defaultProduct.description_en,
          item.gid,
          ProductTypeEnum.Waffle
        );

        products.push(product);
      });
      item.production_list.interaction_article_list.forEach((group) => {
        const product = this.mapProductsGroup(group, item.gid);

        products.push(product);
      });
    });

    return products;
  }

  private mapProductsGroup(group: IInteractionProductGroup, gid: number): IApiProduct {
    const defaultProduct =
      group.article_list.find((p) => p.is_default === 1) || group.article_list[0];

    const product: IApiProduct = this.getBaseProduct(
      defaultProduct,
      group.description,
      gid,
      ProductTypeEnum.Drink
    );

    group.article_list.forEach((p) => {
      const variant = this.getProductVariant(p);

      this.addProductVariant(product, variant, p);
    });

    return product;
  }

  private getProductVariant(product: IVariantProduct): ProductVariantsEnum {
    if (product.is_hot === ProductTemperatureEnum.Hot) {
      return ProductVariantsEnum.Hot;
    }

    if (productSizes.includes(product.size)) {
      return getProductVariantFromSize(product.size);
    }

    return ProductVariantsEnum.Default;
  }

  private getBaseProduct(
    defaultProduct: IVariantProduct,
    defaultDescription: string,
    gid: number,
    type: ProductTypeEnum
  ): IApiProduct {
    const {
      id,
      article_group,
      code,
      vat,
      image_str,
      price,
      description_cn,
      i_desc_cn,
      i_desc_en,
      invalid_start,
      invalid_end,
      comments,
      toppings,
      group_a_id,
      group_b_id,
      group_c_id,
      group_d_id,
    } = defaultProduct;

    return {
      id,
      gid,
      type,
      image_str,
      description: {
        [ProductVariantsEnum.Default]: defaultDescription,
      },
      code: {
        [ProductVariantsEnum.Default]: code,
      },
      price: {
        [ProductVariantsEnum.Default]: price,
      },
      vat: {
        [ProductVariantsEnum.Default]: vat,
      },
      variants: [ProductVariantsEnum.Default],
      variantsIds: {
        [ProductVariantsEnum.Default]: id,
      },
      article_group,
      description_cn,
      i_desc_cn,
      i_desc_en,
      invalid_start,
      invalid_end,
      comments,
      toppings,
      group_letter_id: [group_a_id || 0, group_b_id || 0, group_c_id || 0, group_d_id || 0],
    };
  }

  private addProductVariant(
    product: IApiProduct,
    variant: ProductVariantsEnum,
    variantProduct: IVariantProduct
  ) {
    product.variants.push(variant);
    product.description[variant] = variantProduct.description_en;
    product.price[variant] = variantProduct.price;
    product.vat[variant] = variantProduct.vat;
    product.code[variant] = variantProduct.code;
    product.variantsIds[variant] = variantProduct.id;
  }

  private getProductsToppings(
    data: IProductResponse,
    products: IApiProduct[]
  ): Record<number, IToppingGroup[]> {
    const products_toppings: Record<number, IToppingGroup[]> = {};

    products.forEach((product) => {
      products_toppings[product.id] = [];

      const productToppings = data.article_topping_list.find(
        (p) => p.article_id === product.id && p.gid === product.gid
      );

      if (productToppings) {
        productToppings.article_topping_list.forEach((group) => {
          products_toppings[product.id].push(this.getToppingGroup(group));
        });
      } else {
        const categoryToppings = data.topping_list.find((c) => c.gid === product.gid);

        if (categoryToppings) {
          categoryToppings.topping_group_list.forEach((group) => {
            products_toppings[product.id].push(this.getToppingGroup(group));
          });
        }
      }
    });

    return products_toppings;
  }

  private getToppingGroup(group: IArticleToppingInner): IToppingGroup {
    return {
      id: uuidv4(),
      description: group.topping_group_description,
      maximumUsage: +group.maximum_usage,
      toppings: group.topping_list.map((t) => t.topping_id),
    };
  }

  private getAllToppings(data: IProductResponse): IApiTopping[] {
    const toppings: IApiTopping[] = [];
    data.article_topping_list.forEach((category) => {
      category.article_topping_list.forEach((group) => {
        group.topping_list.forEach((topping) => {
          if (!toppings.some((t) => t.topping_id === topping.topping_id)) {
            toppings.push(topping);
          }
        });
      });
    });

    return toppings;
  }

  private getAllOptions(data: IProductResponse): ICommentItem[] {
    const options: ICommentItem[] = [];

    data.article_comment_list.forEach((group) => {
      group.article_comment_list.forEach((option) => {
        option.comment_list.forEach((i) => {
          if (!options.some((o) => o.comment_id === i.comment_id)) {
            options.push(i);
          }
        });
      });
    });

    return options;
  }

  private getProductsOptions(
    data: IProductResponse,
    products: IApiProduct[]
  ): Record<number, ProductVariantsOptionsType> {
    const products_options: Record<number, ProductVariantsOptionsType> = {};

    products.forEach((product) => {
      products_options[product.id] = {};

      Object.keys(product.variantsIds).forEach((variant) => {
        const productVariant = variant as ProductVariantsEnum;
        const variantId = product.variantsIds[productVariant];
        const productOptionsGroups: IProductOptionsGroup[] = [];

        const variantOptions = data.article_comment_list.find(
          (c) => c.article_id === variantId && c.gid === product.gid
        );

        if (variantOptions) {
          variantOptions.article_comment_list.forEach((group) => {
            productOptionsGroups.push(this.getOptionsGroup(group));
          });
        } else {
          const groupOptions = data.comment_list.find((group) => group.gid === product.gid);

          if (groupOptions) {
            groupOptions.comment_group_list.forEach((group) => {
              productOptionsGroups.push(this.getOptionsGroup(group));
            });
          }
        }

        products_options[product.id][productVariant] = productOptionsGroups;
      });
    });

    return products_options;
  }

  private getOptionsGroup(group: IComment | IArticleComment): IProductOptionsGroup {
    return {
      id: uuidv4(),
      description: group.comment_group_description,
      items: group.comment_list.map((i) => i.comment_id),
    };
  }
}

export default new ProductsApi();
