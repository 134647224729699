import { ComponentType, useEffect, useMemo, useRef, useState } from 'react';
import { throttle } from 'lodash';

import { useStartOver } from '@hooks/useStartOver';

import { AreYouHereModal } from '@components/Layout';

const withInactivityPrompt = <P extends object>(
  WrappedComponent: ComponentType<P>,
  inactiveMsTime = 30000
) => {
  return (props: P) => {
    const [showPrompt, setShowPrompt] = useState(false);
    const inactivityTimeoutRef = useRef<number | undefined>(undefined);
    const handleReset = useStartOver();

    const resetTimeout = () => {
      clearTimeout(inactivityTimeoutRef.current);
      inactivityTimeoutRef.current = window.setTimeout(() => {
        setShowPrompt(true);
      }, inactiveMsTime);
    };

    const handleActivity = useMemo(() => {
      return throttle(() => {
        resetTimeout();
        if (showPrompt) {
          setShowPrompt(false);
        }
      }, 500);
    }, [showPrompt, inactivityTimeoutRef]);

    useEffect(() => {
      resetTimeout();
      document.addEventListener('mousemove', handleActivity);
      document.addEventListener('click', handleActivity);

      return () => {
        document.removeEventListener('mousemove', handleActivity);
        document.removeEventListener('click', handleActivity);
        clearTimeout(inactivityTimeoutRef.current);
      };
    }, [handleActivity]);

    const navigateToHomePage = () => {
      setShowPrompt(false);
      handleReset();
    };

    return (
      <div>
        {showPrompt && <AreYouHereModal onClose={navigateToHomePage} />}
        <WrappedComponent {...props} />
      </div>
    );
  };
};

export default withInactivityPrompt;
