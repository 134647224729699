import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGiftCard } from '@interfaces/GiftCard';
import {
  getMemberGiftCards,
  getMemberRewards,
  validateGiftCard,
} from '@store/slices/member/member.thunk';

interface IState {
  giftCards: IGiftCard[];
  pointBalance: number;
  validateCardErrorMessage: string;
  phoneNumber: number | null;
  rewards: any[];
}

const initialState: IState = {
  giftCards: [],
  pointBalance: 0,
  validateCardErrorMessage: '',
  phoneNumber: null,
  rewards: []
};

const { reducer, actions } = createSlice({
  name: 'member',
  initialState,
  reducers: {
    clearMember(state) {
      state.giftCards = initialState.giftCards;
      state.pointBalance = initialState.pointBalance;
      state.validateCardErrorMessage = initialState.validateCardErrorMessage;
    },
    clearError(state) {
      state.validateCardErrorMessage = '';
    },
    setPhoneNumber(state, { payload }: PayloadAction<number | null>) {
      state.phoneNumber = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMemberGiftCards.fulfilled, (state, { payload }) => {
      state.giftCards = [...payload];
    });
    builder.addCase(validateGiftCard.fulfilled, (state, { payload }) => {
      state.giftCards.push(payload);
    });
    builder.addCase(validateGiftCard.rejected, (state, { error }) => {
      state.validateCardErrorMessage = error.message || '';
    });
    builder.addCase(getMemberRewards.fulfilled, (state, { payload }) => {
      state.pointBalance = payload.pointBalance;
      state.rewards = payload.data;
    });
  },
});

export const { clearMember, clearError, setPhoneNumber } = actions;

export default reducer;
