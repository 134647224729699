import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { validateShop } from '@store/slices/shop/shop.thunk';

interface IState {
  isInitialized: boolean;
}

const initialState: IState = {
  isInitialized: false,
};

const { reducer, actions } = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsInitialized(state, { payload }: PayloadAction<boolean>) {
      state.isInitialized = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(validateShop.fulfilled, (state) => {
      state.isInitialized = true;
    });
    builder.addCase(validateShop.rejected, (state) => {
      state.isInitialized = true;
    });
  },
});

export const { setIsInitialized } = actions;

export default reducer;
