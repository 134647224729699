import React from 'react';
import { compose } from '@reduxjs/toolkit';
import { property } from 'lodash';
import classNames from 'classnames';

import styles from './Checkbox.module.scss';

type PropsType = {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  className?: string;
  name?: string;
  label?: string;
  disabled?: boolean;
};

const Checkbox: React.FC<PropsType> = ({
  checked,
  onChange = () => undefined,
  className,
  name,
  label,
  disabled,
}) => {
  return (
    <label className={classNames(styles.label, disabled && styles.disabled, className)}>
      {label}
      <input
        type="checkbox"
        className={styles.input}
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={compose(onChange, property('target.checked'))}
      />
      <span className={styles.checkmark} />
    </label>
  );
};

export default React.memo(Checkbox);
