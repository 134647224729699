import React from 'react';

import { IOrderView } from '@interfaces/Order';
import { useNoScroll } from '@hooks/useNoScroll';
import { Button } from '@components/Common';

import styles from './SoldOutPopup.module.scss';

type PropsType = {
  items: IOrderView[];
  onClose: () => void;
};

const SoldOutPopup: React.FC<PropsType> = ({ items, onClose }) => {
  useNoScroll();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>Oops!</h1>
        <div className={styles.list}>
          <p>Those products that you are trying to order were sold out:</p>
          {items?.map((el) => (
            <p key={el.id}>– {el?.product?.title}</p>
          ))}
        </div>
        <Button className={styles.btnGot} onClick={onClose}>
          Got It
        </Button>
      </div>
    </div>
  );
};

export default React.memo(SoldOutPopup);
