import React from 'react';

import { getBase64ImageSrc } from '@utils/getBase64ImageSrc';
import styles from './ProductCartItemHeader.module.scss';
import { IOrderView } from '@interfaces/Order';

import { CloseButton } from '@components/Common';

type PropsType = {
  order: IOrderView;
  onRemove(orderId: string): void;
};

const ProductCartItemHeader: React.FC<PropsType> = ({ order, onRemove }) => {
  const { id, product } = order;

  const handleRemove = () => {
    onRemove(id);
  };

  return (
    <div className={styles.container}>
      {order.promo?.promoAction === 2 && (
        <p className={styles.promo}>
          {order.promo?.type === 1 && `$${order.promo.value} off on ITEM`}
          {order.promo?.type === 3 && `%${order.promo.value} off on ITEM`}
        </p>
      )}
      <div className={styles.info}>
        <img className={styles.image} src={getBase64ImageSrc(product.imgSrc)} alt={product.title} />
        <h2>{product.title}</h2>
      </div>
      <CloseButton className={styles.close} onClick={handleRemove} />
    </div>
  );
};

export default React.memo(ProductCartItemHeader);
