import React, { useMemo } from 'react';
import { IOrderView } from '@interfaces/Order';
import styles from './ProductCartItem.module.scss';
import {
  ProductCartItemBody,
  ProductCartItemFooter,
  ProductCartItemHeader,
} from '@components/Products';
import { calculateOrderTotal } from '@store/slices/order/order.utils';

type PropsType = {
  order: IOrderView;
  onRemove(orderId: string): void;
  onChangeQuantity(orderId: string, value: number): void;
  onUpdate(orderId: string): void;
};

const ProductCartItem: React.FC<PropsType> = ({ order, onRemove, onChangeQuantity, onUpdate }) => {
  const handleChangeQuantity = (value: number) => {
    onChangeQuantity(order.id, value);
  };

  const orderPrice = useMemo(() => calculateOrderTotal(order), [order]);
  let orderPriceWithPromo = null;
  if (order.promo?.value && order.promo.quantity) {
    switch (order.promo.type) {
      case 1: 
        orderPriceWithPromo = orderPrice - order?.promo?.value * order.promo.quantity;
        break;
      case 3: 
        orderPriceWithPromo = orderPrice - order.product.price * order.promo.value / 100 * order.promo.quantity
        break

    }
  }

  return (
    <div className={styles.container} onClick={() => onUpdate(order.id)}>
      <ProductCartItemHeader order={order} onRemove={onRemove} />
      <ProductCartItemBody toppings={order.toppings} options={order.options} />
      <ProductCartItemFooter
        orderPrice={orderPrice}
        quantity={order.quantity}
        orderPriceWithPromo={orderPriceWithPromo}
        onChangeQuantity={handleChangeQuantity}
      />
    </div>
  );
};

export default React.memo(ProductCartItem);
