export const getShopDataFromUrl = (params: string[]): Record<string, string> => {
  const data: Record<string, string> = {};

  for (const param of params) {
    const [key, value] = param.split('=');
    data[key] = value;
  }

  return data;
};
