import React from 'react';

import styles from './ToppingSelect.module.scss';
import { ITopping } from '@interfaces/Topping';
import { IOrderTopping } from '@interfaces/Order';

import { Checkbox, Counter } from '@components/Common';

type PropsType = {
  groupId: string;
  topping: ITopping;
  selected?: IOrderTopping;
  disabled?: boolean;
  maxQuantity?: number;
  onChange?: (toppingId: number, groupId: string, quantity: number) => void;
  onDelete?: (toppingId: number) => void;
};

const ToppingSelect: React.FC<PropsType> = ({
  groupId,
  topping,
  selected,
  maxQuantity,
  disabled,
  onDelete = () => undefined,
  onChange = () => undefined,
}) => {
  const handleCheckboxChange = (checked: boolean) => {
    if (checked && !selected) {
      onChange(topping.id, groupId, 1);
    } else if (selected) {
      onDelete(selected.toppingId);
    }
  };

  const handleCounterChange = (quantity: number) => {
    if (selected) {
      if (quantity > 0) {
        onChange(selected.toppingId, groupId, quantity);
      } else {
        onDelete(selected.toppingId);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <Checkbox
          className={styles.checkbox}
          label={topping.description}
          checked={!!selected}
          disabled={disabled}
          onChange={handleCheckboxChange}
        />
        <p className={styles.price}>+${topping.price.toFixed(2)}</p>
      </div>
      {selected && (
        <Counter
          className={styles.counter}
          value={selected.quantity}
          max={maxQuantity}
          onChange={handleCounterChange}
        />
      )}
    </div>
  );
};

export default React.memo(ToppingSelect);
