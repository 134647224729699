import React from 'react';
import { Route, Routes } from 'react-router-dom';

import routes from '@config/routes';

import NotFoundPage from '@pages/NotFound';
import HomePage from '@pages/Home';
import ProductsPage from '@pages/Products';
import SignIn from '@pages/SignIn';
import CheckOutPage from '@pages/CheckOut';
import PaymentPage from '@pages/Payment';

const Router = () => {
  return (
    <Routes>
      <Route path={routes.NOT_FOUND} element={<NotFoundPage />} />
      <Route path={routes.HOME} element={<HomePage />} />
      <Route path={routes.PRODUCT_LIST} element={<ProductsPage />} />
      <Route path={routes.SIGN_IN} element={<SignIn />} />
      <Route path={routes.CHECK_OUT} element={<CheckOutPage />} />
      <Route path={routes.PAYMENT} element={<PaymentPage />} />
    </Routes>
  );
};

export default React.memo(Router);
