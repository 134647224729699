import React from 'react';
import classNames from 'classnames';

import { IProduct, ProductTypeEnum } from '@interfaces/Product';
import styles from './ProductItem.module.scss';
import { ProductVariantsEnum } from '@interfaces/enums';
import { getBase64ImageSrc } from '@utils/getBase64ImageSrc';
import { isSoldOut } from '@utils/validators';

import { ReactComponent as ProductBtnImage } from '@assets/product-btn.svg';
import soldOutImg from '@assets/sold_out.jpg';

type ProductPropsType = {
  product: IProduct;
  onSelectProduct: () => void;
};

const ProductItem: React.FC<ProductPropsType> = ({ product, onSelectProduct }) => {
  const { imgSrc, title, price, type, invalid_start, invalid_end } = product;
  const soldOut = isSoldOut(invalid_start, invalid_end);

  return (
    <div className={styles.product_item} onClick={soldOut ? undefined : onSelectProduct}>
      <div
        className={classNames(styles.product_image, {
          [styles.product_image__waffle]: type === ProductTypeEnum.Waffle,
          [styles.product_image__drink]: type === ProductTypeEnum.Drink,
        })}
      >
        <img src={soldOut ? soldOutImg : getBase64ImageSrc(imgSrc)} alt="Product Image" />
      </div>
      <span className={styles.product_title}>{title[ProductVariantsEnum.Default]}</span>
      <div className={styles.product_info}>
        <span className={styles.product_price}>
          ${price[ProductVariantsEnum.Default]?.toFixed(2)}
        </span>
        <div className={classNames([styles.product_btn, soldOut && styles.inactive])}>
          <ProductBtnImage />
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
