import {
  IApiProduct,
  IApiTopping,
  ICommentItem,
  IProductApiResponse,
  IToppingWithGroup,
} from '@interfaces/ProductsResponse';
import { IProduct } from '@interfaces/Product';
import { ITopping, IToppingGroup, ProductsToppingsViewType } from '@interfaces/Topping';
import ls from '@utils/ls';
import storage from '@config/storage';
import {
  IProductOption,
  IProductOptionsGroup,
  IProductOptionsGroupView,
  ProductsOptionsViewType,
  ProductVariantsOptionsType,
  ProductVariantsOptionsViewType,
} from '@interfaces/ProductOption';
import { IEntityWithId } from '@interfaces/Entity';
import {
  CustomOptionsEnum,
  ProductSizeEnum,
  ProductVariantsEnum,
  sizeVariants,
  TemperatureEnum,
} from '@interfaces/enums';

export const mapProduct = (product: IApiProduct): IProduct => {
  const {
    id,
    description,
    price,
    image_str,
    type,
    vat,
    variants,
    variantsIds,
    invalid_end,
    invalid_start,
    group_letter_id
  } = product;

  return {
    id,
    title: description,
    price,
    vat,
    imgSrc: image_str,
    type,
    variants,
    variantsIds,
    invalid_end,
    invalid_start,
    group_letter_id
  };
};

export const getProductVariantFromSize = (size: ProductSizeEnum): ProductVariantsEnum => {
  switch (size) {
    case ProductSizeEnum.L:
      return ProductVariantsEnum.Large;
    case ProductSizeEnum.M:
      return ProductVariantsEnum.Regular;
    case ProductSizeEnum.S:
      return ProductVariantsEnum.Small;
    default:
      return ProductVariantsEnum.Default;
  }
};

export const getProductSizeFromVariant = (variant: ProductVariantsEnum): ProductSizeEnum => {
  switch (variant) {
    case ProductVariantsEnum.Large:
      return ProductSizeEnum.L;
    case ProductVariantsEnum.Regular:
    case ProductVariantsEnum.Hot:
      return ProductSizeEnum.M;
    case ProductVariantsEnum.Small:
      return ProductSizeEnum.S;
    default:
      return ProductSizeEnum.D;
  }
};

export const groupProducts = (products: IApiProduct[]): Record<number, IProduct[]> => {
  return products.reduce<Record<number, IProduct[]>>((res, item) => {
    if (Array.isArray(res[item.gid])) {
      res[item.gid].push(mapProduct(item));
    } else {
      res[item.gid] = [mapProduct(item)];
    }
    return res;
  }, {});
};

export const mapTopping = (topping: IApiTopping): ITopping => {
  const { topping_id, topping_description, price, vat, maximum_usage } = topping;

  return {
    id: topping_id,
    description: topping_description,
    price,
    vat,
    maximumUsage: maximum_usage,
  };
};

export const mapIdsToEntities = <E extends IEntityWithId>(
  ids: (number | string)[],
  entities: E[]
): E[] => {
  return ids
    .map((id) => {
      const entity = entities.find((t) => t.id === id);

      return entity || null;
    })
    .filter(Boolean) as E[];
};

export const getProductsToppingsView = (
  productsToppings: Record<number | string, IToppingGroup[]>,
  toppings: ITopping[]
): ProductsToppingsViewType => {
  return Object.keys(productsToppings).reduce<ProductsToppingsViewType>((result, productId) => {
    const productGroups = productsToppings[productId];

    result[productId] = productGroups.map((group) => {
      const productToppings = mapIdsToEntities<ITopping>(group.toppings, toppings);

      return {
        ...group,
        toppings: productToppings,
      };
    });

    return result;
  }, {});
};

export const getOptionsGroupsView = (
  groups: IProductOptionsGroup[],
  options: IProductOption[]
): IProductOptionsGroupView[] => {
  return groups.map((group) => {
    const productOptions = mapIdsToEntities<IProductOption>(group.items, options);

    return {
      ...group,
      items: productOptions,
    };
  });
};

export const getProductsVariantsOptionsView = (
  productVariantsOptions: Record<number | string, ProductVariantsOptionsType>,
  options: IProductOption[]
): Record<number | string, ProductVariantsOptionsViewType> => {
  return Object.keys(productVariantsOptions).reduce<
    Record<number | string, ProductVariantsOptionsViewType>
  >((optionsVariants, productId) => {
    const variants = productVariantsOptions[productId];

    optionsVariants[productId] = Object.keys(variants).reduce<ProductVariantsOptionsViewType>(
      (optionsGroups, variant) => {
        const groups = variants[variant as ProductVariantsEnum];

        if (groups) {
          optionsGroups[variant as ProductVariantsEnum] = getOptionsGroupsView(groups, options);
        }

        return optionsGroups;
      },
      {}
    );

    return optionsVariants;
  }, {});
};

export const getProductsOptionsView = (
  productOptions: Record<number | string, IProductOptionsGroup[]>,
  options: IProductOption[]
): ProductsOptionsViewType => {
  return Object.keys(productOptions).reduce<ProductsOptionsViewType>((result, productId) => {
    const productGroups = productOptions[productId];

    result[productId] = getOptionsGroupsView(productGroups, options);

    return result;
  }, {});
};

export const removePoductsDataFromLocalStorage = () => {
  ls.remove(storage.LS.PRODUCTS);
  ls.remove(storage.LS.TOPPINGS);
  ls.remove(storage.LS.OPTIONS);
  ls.remove(storage.LS.PRODUCTS_TOPPINGS);
  ls.remove(storage.LS.PRODUCTS_OPTIONS);
}

export const saveProductsDataToLocalStorage = ({
  products,
  toppings,
  products_toppings,
  options,
  products_options,
}: IProductApiResponse) => {
  ls.set(storage.LS.PRODUCTS, products);
  ls.set(storage.LS.TOPPINGS, toppings);
  ls.set(storage.LS.PRODUCTS_TOPPINGS, products_toppings);
  ls.set(storage.LS.OPTIONS, options);
  ls.set(storage.LS.PRODUCTS_OPTIONS, products_options);
};

export const getProductsDataFromLocalStorage = (): IProductApiResponse | null => {
  const products = ls.get<IApiProduct[]>(storage.LS.PRODUCTS);
  const toppings = ls.get<IToppingWithGroup[]>(storage.LS.TOPPINGS);
  const products_toppings = ls.get<Record<number, IToppingGroup[]>>(storage.LS.PRODUCTS_TOPPINGS);
  const options = ls.get<ICommentItem[]>(storage.LS.OPTIONS);
  const products_options = ls.get<Record<number, ProductVariantsOptionsType>>(
    storage.LS.PRODUCTS_OPTIONS
  );

  if (!products || !toppings || !products_toppings || !options || !products_options) {
    return null;
  }

  return {
    products,
    toppings,
    products_toppings,
    options,
    products_options,
  };
};

export const mapProductOptions = (data: ICommentItem[]): IProductOption[] => {
  return data.map(({ comment_id, comment_description, comment_code }) => ({
    id: comment_id,
    description: comment_description,
    code: comment_code,
  }));
};

export const getProductsCustomOptions = (
  products: Record<number, IProduct[]>,
  customOptionsGroups: Record<CustomOptionsEnum, IProductOptionsGroup>
): Record<number, IProductOptionsGroup[]> => {
  const options: Record<number, IProductOptionsGroup[]> = {};

  for (let gid in products) {
    const productsList = products[gid];

    productsList.forEach((p) => {
      options[p.id] = [];

      if (p.variants.includes(ProductVariantsEnum.Hot)) {
        const option = customOptionsGroups[CustomOptionsEnum.Temperature];

        if (option) {
          options[p.id].push(option);
        }
      }

      if (p.variants.some((v) => sizeVariants.includes(v))) {
        let option = customOptionsGroups[CustomOptionsEnum.Size];

        if (option) {
          const items = sizeVariants.filter((s) => p.variants.includes(s));

          options[p.id].push({ ...option, items });
        }
      }
    });
  }

  return options;
};

export const getProductVariantFromOptions = (
  options: Record<string, number | string>
): ProductVariantsEnum => {
  if (options[CustomOptionsEnum.Temperature] === TemperatureEnum.Hot) {
    return ProductVariantsEnum.Hot;
  }

  if (options[CustomOptionsEnum.Size]) {
    return options[CustomOptionsEnum.Size] as ProductVariantsEnum;
  }

  return ProductVariantsEnum.Default;
};

export const getProductCustomOptionsFromVariant = (variant: ProductVariantsEnum) => {
  const options: Partial<Record<CustomOptionsEnum, number | string>> = {};

  if (variant === ProductVariantsEnum.Hot) {
    options[CustomOptionsEnum.Temperature] = TemperatureEnum.Hot;
  } else if (sizeVariants.includes(variant)) {
    options[CustomOptionsEnum.Size] = variant;
  }

  return options;
};

export const filterCustomOptions = (
  customOptions: IProductOptionsGroupView[],
  customSelectedOptions: Record<string, number | string>
) => {
  return customOptions.filter((g) => {
    return customSelectedOptions[CustomOptionsEnum.Temperature] === TemperatureEnum.Hot
      ? g.id !== CustomOptionsEnum.Size
      : true;
  });
};
