import React from 'react';

import styles from './ProductCartItemBody.module.scss';
import { IOrderOptionView, IOrderToppingView } from '@interfaces/Order';
import { displayDollarsAmount } from '@utils/displayDollarsAmount';

type PropsType = {
  toppings: IOrderToppingView[];
  options: IOrderOptionView[];
};

const ProductCartItemBody: React.FC<PropsType> = ({ toppings, options }) => {
  return (
    <div className={styles.container}>
      <div className={styles.optionsBox}>
        {options.map((option) => (
          <p key={option.id}>
            {option.title}: <span>{option.value}</span>
          </p>
        ))}
      </div>
      <div>
        {!!toppings.length && <h4>Options:</h4>}
        {toppings.map(({ topping, quantity }, index) => (
          <div className={styles.topping} key={index}>
            <p>
              {topping.description} <span>x {quantity}</span>
            </p>
            <p>{displayDollarsAmount(topping.price * quantity)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(ProductCartItemBody);
