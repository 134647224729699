import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from './Counter.module.scss';
import minusSVG from '@assets/minus.svg';
import plusSVG from '@assets/plus.svg';

type PropsType = {
  value?: number;
  onChange?: (value: number) => void;
  className?: string;
  min?: number;
  max?: number;
  variant?: 'primary' | 'secondary';
};

const Counter: React.FC<PropsType> = ({
  value = 0,
  min = 0,
  max = Infinity,
  onChange,
  className,
  variant = 'primary',
}) => {
  const [count, setCount] = useState(value);

  useEffect(() => {
    if (onChange) {
      onChange(count);
    }
  }, [count]);

  const stopPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };

  const plus = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setCount((prev) => Math.min(prev + 1, max));
  };

  const minus = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setCount((prev) => Math.max(prev - 1, min));
  };

  return (
    <div
      className={classNames(
        styles.counter,
        value === max && styles.disabled,
        className,
        styles[variant]
      )}
      onClick={stopPropagation}
    >
      <button className={styles.btn} onClick={minus}>
        <img src={minusSVG} alt="minus" />
      </button>
      <div className={styles.value}>{count}</div>
      <button className={styles.btn} onClick={plus}>
        <img src={plusSVG} alt="plus" />
      </button>
    </div>
  );
};

export default React.memo(Counter);
