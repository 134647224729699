export default {
  LS: {
    PRICE_LEVEL_KIOSK: 'price_level_kiosk',
    REGION_ID: 'region_id',
    SHOP_ID: 'shop_id',
    DEVICE_ID: 'device_id',
    ENCRYPTION_CODE: 'encryption_code',
    PRODUCTS: 'products',
    TOPPINGS: 'toppings',
    PRODUCTS_TOPPINGS: 'products_toppings',
    OPTIONS: 'options',
    PRODUCTS_OPTIONS: 'products_options',
    CLOVER_API_TOKEN: 'clover_api_token',
    CLOVER_DEVICE_ID: 'clover_device_id',
    POS_ID: 'pos_id',
    CLOVER_IP: 'clover_pay_url',
    TRANSACTION_IP: 'upload_trans_url',
    SHARED_POINTER_ID: 'shared_pointer_id'
  },
};
