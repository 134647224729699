import React, { useState } from 'react';

import styles from './PhoneModal.module.scss';

import { Button, NumKeyboard, Input } from '@components/Common';
import { PHONE_NUMBER_LENGTH } from '@config/constants';

type PropsType = {
  onConfirm(num: string): void;
  onClose(): void;
  validationError?: string;
};

const PhoneModal: React.FC<PropsType> = ({ onConfirm, onClose, validationError }) => {
  const [value, setValue] = useState('');

  const handleChange = (num: number) => {
    setValue((prevState) => {
      if (prevState.length >= PHONE_NUMBER_LENGTH) {
        return prevState;
      }

      return prevState + num;
    });
  };

  const handleDelete = () => {
    setValue((prevState) => prevState.slice(0, -1));
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>Continue as a guest</h1>
        <h6>You’ll receive an SMS alert when your order is ready</h6>
        <div>
          <Input
            value={value}
            label="Phone Number"
            placeholder="Phone Number"
            type="phone"
            className={styles.input}
            errorText={validationError}
            invalid={!!validationError}
          />
        </div>
        <NumKeyboard handleButtonClick={handleChange} handleDelete={handleDelete} disabledDot />
        <div className={styles.confirm}>
          <Button size="big" disabled={!value} onClick={() => onConfirm(value)}>
            Confirm
          </Button>
        </div>
      </div>
      <Button id={styles.back} onClick={onClose} variant="secondary">
        Go Back
      </Button>
    </div>
  );
};

export default React.memo(PhoneModal);
