import React from 'react';

import styles from './MembershipCard.module.scss';

import { PasswordStep, ScanCardStep } from '@components/SignIn';

export type PropsType = {
  password: string;
  step: 'accountId' | 'password';
  hasError: boolean;
  validationError?: string;
  onCheckScannedCard(memberId: string): void;
  onClearError(): void;
  onPasswordChange(value: string): void;
  onClickContinue(): void;
};

const MembershipCardTab: React.FC<PropsType> = ({
  password,
  step,
  hasError,
  validationError,
  onCheckScannedCard,
  onClearError,
  onPasswordChange,
  onClickContinue,
}) => {
  const content = () => {
    if (step === 'accountId') {
      return (
        <ScanCardStep
          handleCheckScannedCard={onCheckScannedCard}
          hasError={hasError}
          handleClearError={onClearError}
        />
      );
    }

    return (
      <PasswordStep
        value={password}
        invalid={hasError}
        validationError={validationError}
        onChange={onPasswordChange}
        onClickContinue={onClickContinue}
      />
    );
  };

  return <div className={styles.container}>{content()}</div>;
};

export default React.memo(MembershipCardTab);
