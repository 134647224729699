import React from 'react';

import styles from './ProductsList.module.scss';
import { IProduct } from '@interfaces/Product';

import { Loader } from '@components/Common';
import ProductItem from '../ProductItem/ProductItem';

type ProductsListProps = {
  products: IProduct[] | null;
  onSelectProduct: (id: number) => void;
};

const ProductsList: React.FC<ProductsListProps> = ({ products, onSelectProduct }) => {
  if (!products) return <Loader />;

  return (
    <div className={styles.products_container}>
      <div className={styles.products_list}>
        {products.map((item) => (
          <ProductItem
            product={item}
            key={item.id}
            onSelectProduct={() => onSelectProduct(item.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductsList;
