import React, { useEffect } from 'react';
import classNames from 'classnames';

import styles from './OptionsSelect.module.scss';
import { IProductOption } from '@interfaces/ProductOption';

type PropsType = {
  value?: number | string;
  onChange?: (value: number | string) => void;
  options?: IProductOption[];
};

const OptionsSelect: React.FC<PropsType> = ({
  options = [],
  value,
  onChange = () => undefined,
}) => {
  if (!options.length) return null

  useEffect(() => {
    if (!value) {
      onChange(options[0].id);
    }
  }, [options]);

  return (
    <div className={styles.container}>
      {options.map((o) => (
        <div key={o.id} className={styles.item} onClick={() => onChange(o.id)}>
          <div className={classNames(styles.check, value === o.id && styles.check__active)} />
          <p className={styles.title}>{o.description}</p>
        </div>
      ))}
    </div>
  );
};

export default React.memo(OptionsSelect);
