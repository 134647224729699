import React from 'react';
import styles from './CartButton.module.scss';
import simpleCartIcon from '@assets/simpleCartIcon.svg';

type PropsType = {
  onClick(): void;
  value: number;
};

const CartButton: React.FC<PropsType> = ({ onClick, value }) => {
  return (
    <button className={styles.button} onClick={onClick}>
      <img src={simpleCartIcon} alt="cart" />
      {!!value && <div className={styles.counter}>{value}</div>}
    </button>
  );
};

export default React.memo(CartButton);
