import React, { ComponentType, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@hooks/redux';
import { isShopVerifiedSelector } from '@store/slices/shop/shop.selectors';

const withShopVerification = <P extends object>(Component: ComponentType<P>): React.FC<P> => {
  return (props: P) => {
    const navigate = useNavigate();
    const isShopVerified = useAppSelector(isShopVerifiedSelector);

    useEffect(() => {
      if (!isShopVerified) {
        navigate('/');
      }
    }, [navigate]);

    return <Component {...props} />;
  };
};

export default withShopVerification;
