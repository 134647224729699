import { FC } from 'react';

import Promotion from '@assets/promo.png';

import styles from './PromoModal.module.scss';
import { IPromotion } from '@interfaces/Promotions';

interface PropsType {
  promo: IPromotion[];
  promoId: number;
  onClose: () => void;
}

const PromoModal: FC<PropsType> = ({ promo, promoId, onClose }) => {
  const findAvailablePromo = promo.find((item) => item.id === promoId);

  if (!findAvailablePromo) return null;
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>{findAvailablePromo.description}</h1>
        <img
          src={findAvailablePromo.imageUrl}
          alt=""
          onError={(e) => (e.currentTarget.src = Promotion)}
        />
        <button onClick={onClose}>Got It</button>
      </div>
    </div>
  );
};

export default PromoModal;
