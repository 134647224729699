import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOrder } from '@interfaces/Order';
import { ITipTemplate } from '@interfaces/Tips';
import { tipsTemplate } from './order.data';
import { getRewardInformation, getShopPromotions } from './order.thunk';
import { IPromotion } from '@interfaces/Promotions';
import { IProduct } from '@interfaces/Product';
import { getMaxPromotions, getPromoProductActivationAmount } from './order.utils';

interface IState {
  tip: number | null;
  orders: IOrder[];
  selectedGiftCardsIds: string[];
  appliedBonuses: Record<string, number>;
  tipsTemplate: ITipTemplate[];
  ordersReward: any[];
  promotions: {
    data: IPromotion[];
    maxPromo: Record<string, number>;
    promoProducts: Record<string, Record<string, IProduct[]>>;
    promoActivationAmount: Record<string, Record<string, number>>;
    availablePromo: number[];
    promoIsActive: boolean
    activePromoId: number[];
  };
}

const initialState: IState = {
  tip: null,
  orders: [],
  selectedGiftCardsIds: [],
  appliedBonuses: {},
  tipsTemplate,
  ordersReward: [],
  promotions: {
    data: [],
    maxPromo: {},
    promoProducts: {},
    promoActivationAmount: {},
    availablePromo: [],
    promoIsActive: false,
    activePromoId: []
  },
};

const { reducer, actions } = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setTip(state, { payload }: PayloadAction<number | null>) {
      state.tip = payload;
    },
    setCard(state, { payload }: PayloadAction<string>) {
      state.selectedGiftCardsIds.push(payload);
    },
    removeCard(state, { payload }: PayloadAction<string>) {
      state.selectedGiftCardsIds = state.selectedGiftCardsIds.filter(
        (cardId) => cardId !== payload
      );
    },
    applyBonus(state, { payload }: PayloadAction<{ orderId: string; value: number }>) {
      const { orderId, value } = payload;
      state.appliedBonuses[orderId] = value;
    },
    removeBonus(state, { payload }: PayloadAction<string>) {
      delete state.appliedBonuses[payload];
    },
    clearOrderPayment(state) {
      state.appliedBonuses = initialState.appliedBonuses;
      state.selectedGiftCardsIds = initialState.selectedGiftCardsIds;
      state.tip = initialState.tip;
    },
    clearOrder(state) {
      state.appliedBonuses = initialState.appliedBonuses;
      state.selectedGiftCardsIds = initialState.selectedGiftCardsIds;
      state.tip = initialState.tip;
      state.orders = initialState.orders;
      state.promotions = initialState.promotions;
    },
    addOrder(state, { payload }: PayloadAction<IOrder>) {
      state.orders.push(payload);
    },
    updateOrder(state, { payload }: PayloadAction<IOrder>) {
      state.orders = state.orders.map((o) => {
        if (o.id === payload.id) {
          return { ...payload };
        }

        return o;
      });
    },
    removeOrder(state, { payload }: PayloadAction<string>) {
      delete state.appliedBonuses[payload];
      const order = state.orders.find((order) => order.id === payload);

      if (order?.promo) {
        const index = state.promotions.activePromoId.indexOf(order.promo.id)
        if (index > -1) {
          state.promotions.activePromoId.splice(index, 1);
        }
      }

      state.orders = state.orders.filter((order) => order.id !== payload);
    },
    removeSoldOutItems(state, { payload }: PayloadAction<string[]>) {
      state.orders = state.orders.filter((order) => !payload.includes(order.id));
    },
    changeProductQuantity(state, { payload }: PayloadAction<{ orderId: string; value: number }>) {
      state.orders = state.orders.map((o) => {
        if (o.id === payload.orderId) {
          return {
            ...o,
            quantity: payload.value,
          };
        }

        return o;
      });
    },

    clearPromo(state) {
      state.orders.forEach(order => delete order.promo)
    },
    
    checkIfPromoIsAvailable(state) {
      state.promotions.promoActivationAmount = getPromoProductActivationAmount(
        state.promotions.data
      );
      state.promotions.maxPromo = getMaxPromotions(state.promotions.data);
      state.promotions.availablePromo = [];
      state.promotions.activePromoId = []
      state.promotions.promoIsActive = false;
      const promoIdWithProducts = state.promotions.promoProducts;

      state.orders.forEach(order => delete order.promo)

      state.orders.forEach((order) => {
        for (const key in promoIdWithProducts) {
          for (const [promoKey, value] of Object.entries(promoIdWithProducts[key])) {
            const product = value.some((product) => product.id === order.productId);
            if (product && promoKey === 'to' && state.promotions.maxPromo[+key] > 0) {
              // if (!state.promotions.availablePromo.includes(+key)) {
              for (let i = 0; i < order.quantity; i++) {
                state.promotions.availablePromo.push(+key);
              }
              // }
            }
          }
        }
      });
      state.orders.forEach((order) => {
        for (const key in promoIdWithProducts) {
          for (const [promoKey, value] of Object.entries(promoIdWithProducts[key])) {
            const product = value.some((product) => product.id === order.productId);
            if (
              (product && !promoIdWithProducts[key]['to'] && state.promotions.maxPromo[+key] > 0) ||
              (product &&
                promoIdWithProducts[key]['to'] &&
                state.promotions.availablePromo.includes(+key) &&
                promoKey === 'for' &&
                state.promotions.maxPromo[+key] > 0)
            ) {
              const promo = state.promotions.data.find((promo) => promo.id === +key);

              if (state.promotions.activePromoId.length && !state.promotions.activePromoId.includes(promo?.id || 0)) return

              const maxPromo = state.promotions.maxPromo[+key];
              const promoProductQty = state.promotions.promoActivationAmount[key][promoKey];
              state.orders = state.orders.map((item) => {
                if (item.id === order.id) {
                  let orderPromoQty = 0;
                  const activationPromoAmount = order.quantity / promoProductQty;

                  if ( state.promotions.maxPromo[+key] > 0) {
                    if (Math.floor(activationPromoAmount)) {
                      if (Math.floor(activationPromoAmount) <= maxPromo) {
                        orderPromoQty = state.promotions.availablePromo.length ? state.promotions.availablePromo.length > order.quantity ? order.quantity : state.promotions.availablePromo.length : Math.floor(activationPromoAmount);
                        state.promotions.maxPromo[+key] -= Math.floor(activationPromoAmount);
                        state.promotions.activePromoId.push(+key)
                        // state.promotions.promoActivationAmount[key][promoKey] = 0;
                      } else {
                        orderPromoQty = state.promotions.availablePromo.length ? state.promotions.availablePromo.length : maxPromo;
                        state.promotions.maxPromo[+key] = 0;
                        state.promotions.promoActivationAmount[key][promoKey] = 0;
                        state.promotions.activePromoId.push(+key)
                      }
                      state.promotions.promoIsActive = true;
                      state.promotions.promoActivationAmount[key][promoKey] = getPromoProductActivationAmount(
                        state.promotions.data
                      )[key][promoKey];
                      state.promotions.availablePromo = state.promotions.availablePromo.splice(orderPromoQty)
                      
                      return {
                        ...item,
                        promo: {
                          code: promo?.code,
                          promoAction: promo?.actions[0].activationAction,
                          value: promo?.actions[0].discountValue,
                          type: promo?.actions[0].discountType,
                          description: promo?.description,
                          quantity: orderPromoQty,
                          id: +key,
                        },
                      };
                    } else {
                      state.promotions.promoActivationAmount[key][promoKey] -= order.quantity;
                    }
                  }

                 
                }
                return item;
              });
            }

            if (
              promoKey === 'for' &&
              promoIdWithProducts[key]['to'] &&
              !state.promotions.availablePromo.includes(+key)
            )
              delete order.promo;
          }
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRewardInformation.fulfilled, (state, { payload }) => {
      state.ordersReward = payload.data;
    });
    builder.addCase(getShopPromotions.fulfilled, (state, { payload }) => {
      state.promotions.data = payload.promotions;
      state.promotions.promoProducts = payload.promotionsProducts;
    });
  },
});

export const {
  setTip,
  setCard,
  removeCard,
  applyBonus,
  removeBonus,
  clearOrderPayment,
  clearOrder,
  addOrder,
  updateOrder,
  removeOrder,
  changeProductQuantity,
  removeSoldOutItems,
  checkIfPromoIsAvailable,
  clearPromo
} = actions;

export default reducer;
