import React, { useCallback, useEffect, useState } from 'react';
import { compose } from '@reduxjs/toolkit';
import { property } from 'lodash';
import styles from './AddNewGiftCardForm.module.scss';
import { Input, Toast } from '@components/Common';

type PropsType = {
  onAddNewCard(cardNumber: string, pin: string): void;
  hasErrorGiftCard: string;
  handleClearError(): void;
};

const AddNewGiftCardForm: React.FC<PropsType> = ({
  onAddNewCard,
  hasErrorGiftCard,
  handleClearError,
}) => {
  const [cardNumber, setCardNumber] = useState('');
  const [pin, setPin] = useState('');

  useEffect(() => {
    if (hasErrorGiftCard) {
      setPin('');
      setCardNumber('');
    }
  }, [hasErrorGiftCard]);

  const onAddButton = () => {
    onAddNewCard(cardNumber, pin);

    setPin('')
    setCardNumber('')
  };

  const checkCardCredentials = useCallback(() => {
    if (cardNumber.length !== 16) return true;
    if (![4, 5].includes(pin.length)) return true

    return false
  }, [cardNumber, pin])
  return (
    <div className={styles.container}>
      <h1>pay by gift Card</h1>
      <div className={styles.inputBox}>
        <Input
          id={styles.inputNumber}
          value={cardNumber}
          placeholder="Card Number"
          onChange={compose(setCardNumber, property('target.value'))}
          name="cardNumber"
          readOnly={false}
          maxLength={16}
        />
        <Input
          id={styles.inputPin}
          value={pin}
          placeholder="Pin"
          onChange={compose(setPin, property('target.value'))}
          name="pin"
          readOnly={false}
          maxLength={4}
          onChangeKeyInput={(value: string) => setPin(value)}
        />
        <button onClick={onAddButton} disabled={checkCardCredentials()}>+</button>
      </div>
      {hasErrorGiftCard && <Toast title={hasErrorGiftCard} onClose={handleClearError} />}
    </div>
  );
};

export default React.memo(AddNewGiftCardForm);
