import { createSlice } from '@reduxjs/toolkit';

import { ICategory } from '@interfaces/Category';
import { getCategories } from './categories.thunk';

interface ICategoriesState {
    data: ICategory[];
    error: string
}

const initialState: ICategoriesState = {
    data: [],
    error: ''
}


const { reducer } = createSlice({
    name: 'categories',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getCategories.fulfilled, (state, actions) => {
            const data = actions.payload;
            state.data = data
        })
    }
});


export default reducer;