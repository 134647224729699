import styles from './SideBar.module.scss';

type PropsType = {
  children: React.ReactNode;
};


const SideBar: React.FC<PropsType> = ({children}) => {
  return (
    <div className={styles.side_bar}>
        {children}
    </div>
  )
}

export default SideBar