export enum CustomOptionsEnum {
  Temperature = 'temperature',
  Size = 'size',
}

export enum TemperatureEnum {
  Hot = 'hot',
  Cold = 'cold',
}

export enum ProductSizeEnum {
  D = 0,
  L = 1,
  M = 2,
  S = 3,
}

export enum ProductTemperatureEnum {
  Hot = 1,
  Cold = 0,
}

export enum ProductVariantsEnum {
  Default = 'D',
  Large = 'L',
  Regular = 'M',
  Small = 'S',
  Hot = 'H',
}

export const productSizes = [ProductSizeEnum.L, ProductSizeEnum.M, ProductSizeEnum.S];

export const sizeVariants = [
  ProductVariantsEnum.Large,
  ProductVariantsEnum.Regular,
  ProductVariantsEnum.Small,
];
