import React from 'react';
import { IOrderView } from '@interfaces/Order';
import { OrderProduct } from '@components/CheckOut';

import styles from './ProductList.module.scss';

type PropsType = {
  productsList: IOrderView[];
  productsRewardId: number[];
  onUseBonuses(orderId: string, value: number): void;
  onUnUseBonuses(orderId: string): void;
  onRemoveOrder(orderId: string): void;
  onUpdateOrder(orderId: string): void;
  appliedBonuses: Record<string, number>;
  isAuthorized: boolean;
  pointsBalance: number;
  isPromoActive: boolean
};

const ProductList: React.FC<PropsType> = ({
  productsList,
  productsRewardId,
  onUseBonuses,
  onUnUseBonuses,
  appliedBonuses,
  isAuthorized,
  pointsBalance,
  onRemoveOrder,
  onUpdateOrder,
  isPromoActive
}) => {
  return (
    <div className={styles.container}>
      {productsList.map((order, index) => (
        <OrderProduct
          order={order}
          productsRewardId={productsRewardId}
          key={index}
          onUseBonuses={onUseBonuses}
          onUnUseBonuses={onUnUseBonuses}
          appliedBonuses={appliedBonuses}
          isAuthorized={isAuthorized}
          pointsBalance={pointsBalance}
          onRemoveOrder={onRemoveOrder}
          onUpdateOrder={onUpdateOrder}
          isPromoActive={isPromoActive}
        />
      ))}
    </div>
  );
};

export default React.memo(ProductList);
