import { MembershipBaseApi } from '@api/membershipBase.api';
import { ISignInPayload, ISignInResponse } from '@interfaces/SignIn';
import { IGiftCard, INewCardPayload } from '@interfaces/GiftCard';
import { IMemberApiResponse } from '@interfaces/Member';

class MemberApi extends MembershipBaseApi {
  async validatePhone(data: ISignInPayload): Promise<ISignInResponse> {
    const base64 = btoa(`${data.number}:${data.password}`);
    const headers = {
      'PPL-Member-Authorization': `Basic ${base64}`,
    };

    const response = await this.api.post(
      this.endpoints.SIGN_IN_BY_PASSWORD,
      {},
      {
        headers,
      }
    );
    return response.data;
  }
  async getMemberGiftCards(memberId: string): Promise<IMemberApiResponse<IGiftCard[]>> {
    const response = await this.api.get(`${this.endpoints.MEMBER}${memberId}/giftcards`);

    return response.data;
  }
  async getMemberRewards(memberId: string): Promise<{ success: boolean; pointBalance: number ; data: any[]}> {
    const response = await this.api.get(`${this.endpoints.MEMBER}${memberId}/rewards`);

    return response.data;
  }
  async validateGiftCard(cardNumber: string, pin: string): Promise<IMemberApiResponse> {
    const response = await this.api.get(`${this.endpoints.GIFT_CARD}${cardNumber}?pin=${pin}`);

    return response.data;
  }
  async validateMemberId(memberId: string): Promise<IMemberApiResponse<{ memberId: string, firstName: string, phoneCell: string }>> {
    const response = await this.api.get(`${this.endpoints.MEMBER}${memberId}`);

    return response.data;
  }
  async addNewGiftCard(
    memberId: string,
    cardNumber: string,
    body: INewCardPayload
  ): Promise<IMemberApiResponse> {
    const response = await this.api.put(
      `${this.endpoints.MEMBER}${memberId}/giftcard/${cardNumber}`,
      body
    );

    return response.data;
  }
}

export default new MemberApi();
