import React from 'react';

import styles from './ProductCartItemFooter.module.scss';
import { displayDollarsAmount } from '@utils/displayDollarsAmount';
import { MAX_PRODUCTS_PER_ORDER } from '@config/constants';

import { Counter } from '@components/Common';

type PropsType = {
  orderPrice: number;
  orderPriceWithPromo: number | null;
  quantity: number;
  onChangeQuantity(value: number): void;
};

const ProductCartItemFooter: React.FC<PropsType> = ({
  onChangeQuantity,
  quantity,
  orderPrice,
  orderPriceWithPromo,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.price}>
        <h1 className={`${orderPriceWithPromo !== null && styles.crossed}`}>
          {displayDollarsAmount(orderPrice)}
        </h1>
        {orderPriceWithPromo !== null ? (
          orderPriceWithPromo !== 0 ? (
            <h2>{displayDollarsAmount(orderPriceWithPromo || 0)}</h2>
          ) : (
            <h2>Free</h2>
          )
        ) : null}
      </div>
      <Counter value={quantity} onChange={onChangeQuantity} min={1} max={MAX_PRODUCTS_PER_ORDER} />
    </div>
  );
};
export default React.memo(ProductCartItemFooter);
