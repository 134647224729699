import { FC } from 'react';
import { Button } from '@components/Common';
import styles from './ProposeModal.module.scss'


import giftIcon from '@assets/gift.svg';
import loginIcon from '@assets/person.svg'
import lockIcon from '@assets/lock.svg'

interface PropsType {
    onLogin: () => void;
    handleLoginAsGuest(): void;
}

const ProposeModal: FC<PropsType> = ({ onLogin, handleLoginAsGuest}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <h5>Unlock Deals</h5>
                <p>Promotions are available exclusively to logged-in users. Sign in to enjoy our exclusive promotions.</p>
                <div className={styles.circle}>
                   <img src={giftIcon} alt="" />
                </div>
                <div className={styles.conteiner}>
                    <Button id={styles.login} onClick={onLogin}>
                        <img src={loginIcon} alt="" />
                        Sign in
                    </Button>
                    <Button id={styles.loginAsGuest} variant="secondary" onClick={handleLoginAsGuest}>
                        <img src={lockIcon} alt="" />
                        Continue as a guest
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default ProposeModal;
