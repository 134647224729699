import productsApi from '@api/products.api';
import { IProduct } from '@interfaces/Product';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { shopSelector } from '../shop/shop.selectors';
import {
  getProductsDataFromLocalStorage,
  groupProducts,
  mapProductOptions,
  mapTopping,
  saveProductsDataToLocalStorage,
} from '@store/slices/products/products.utils';
import { IToppingGroup, ITopping } from '@interfaces/Topping';
import { IProductOption, ProductVariantsOptionsType } from '@interfaces/ProductOption';
import { getShopDataFromLocalStorage } from '../shop/shop.utils';

export interface IGetProductsDataReturn {
  allProducts: IProduct[];
  products: Record<number, IProduct[]>;
  toppings: ITopping[];
  productsToppings: Record<number, IToppingGroup[]>;
  options: IProductOption[];
  productsOptions: Record<number, ProductVariantsOptionsType>;
}

export const getProductsData = createAsyncThunk<IGetProductsDataReturn>(
  'products/getProducts',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const { id: shopID, regionID, pricelevelID } = shopSelector(state);

    if (!shopID || !regionID || !pricelevelID) {
      throw new Error('Incorrect get products request payload');
    }

    let productsData = getProductsDataFromLocalStorage();

    if (!productsData) {
      productsData = await productsApi.get({
        shopID: +shopID,
        regionID,
        pricelevelID,
      });

      saveProductsDataToLocalStorage(productsData);
    }

    
    const { products, toppings, products_toppings, options, products_options } = productsData;
    
    const allProductApi = Object.values(groupProducts(products)).reduce((res, item) => {
      return [...res, ...item]
    }, [])

    return {
      allProducts: allProductApi,
      products: groupProducts(products),
      productsToppings: products_toppings,
      toppings: toppings.map(mapTopping),
      options: mapProductOptions(options),
      productsOptions: products_options,
    };
  }
);

export const getProductFromBarcode = createAsyncThunk(
  'product/getProductFromBarcode',
  async (barcode: string) => {
    
    const shopInfo = getShopDataFromLocalStorage();
    

    if (!shopInfo) {
      throw new Error("Shop Info dos'n exist");
    }

    const {article_ID} = await productsApi.getProductFromBarcode({ barcode, url: shopInfo.upload_trans_url})
    return article_ID
  }
);
