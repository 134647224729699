import { RootState } from '@store/store';
import { createSelector } from '@reduxjs/toolkit';
import {
  getProductsCustomOptions,
  getProductsOptionsView,
  getProductsToppingsView,
  getProductsVariantsOptionsView,
} from '@store/slices/products/products.utils';

export const shopProductsSelector = (state: RootState) => state.products.products;

export const shopAllProductsSelector = (state: RootState) => state.products.allProducts;

export const toppingsSelector = (state: RootState) => state.products.toppings;

export const productsToppingsSelector = (state: RootState) => state.products.productsToppings;

export const optionsSelector = (state: RootState) => state.products.options;

export const productsOptionsSelector = (state: RootState) => state.products.productsOptions;

export const customOptionsSelector = (state: RootState) => state.products.customOptions;

export const customOptionsGroupsSelector = (state: RootState) => state.products.customOptionsGroups;

export const productsCustomOptionsSelector = createSelector(
  shopProductsSelector,
  customOptionsGroupsSelector,
  getProductsCustomOptions
);

export const productsToppingsViewSelector = createSelector(
  productsToppingsSelector,
  toppingsSelector,
  getProductsToppingsView
);

export const productsOptionsViewSelector = createSelector(
  productsOptionsSelector,
  optionsSelector,
  getProductsVariantsOptionsView
);

export const productsCustomOptionsViewSelector = createSelector(
  productsCustomOptionsSelector,
  customOptionsSelector,
  getProductsOptionsView
);
