import React from 'react';

import styles from './PaymentHeader.module.scss';
import { displayDollarsAmount } from '@utils/displayDollarsAmount';

type PropsType = {
  total: number;
  subtotal: number;
  taxes: number;
  tips: number;
  cardPay: number;
};

const PaymentHeader: React.FC<PropsType> = ({ total, subtotal, taxes, tips, cardPay }) => {
  return (
    <div className={styles.container}>
      <div className={styles.mainText}>
        <h1>Total:</h1>
        <h1>{displayDollarsAmount(total)}</h1>
      </div>
      <div className={styles.subTextContainer}>
        <div className={styles.secondaryText}>
          <h4>Subtotal:</h4>
          <h5>{displayDollarsAmount(subtotal)}</h5>
        </div>
        <div className={styles.secondaryText}>
          <h4>Taxes & Fees:</h4>
          <h5>{displayDollarsAmount(taxes)}</h5>
        </div>
        <div className={styles.secondaryText}>
          <h4>Tips:</h4>
          <h5>{tips ? displayDollarsAmount(tips) : 'Please select from Clover Machine'}</h5>
        </div>
        {!!cardPay && (
          <div className={styles.secondaryText}>
            <h4>Gift Card:</h4>
            <h5>-{displayDollarsAmount(cardPay)}</h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(PaymentHeader);
