import axios, { AxiosInstance } from 'axios';

import env from '@config/env';
import endpoints from '@config/endpoints';
import { xmlParserMiddleware } from '@api/interceptors/xml-parser';

const api = axios.create({
  baseURL: env.ONLINE_ORDER_API_URL,
  headers: { 'content-type': 'application/x-www-form-urlencoded' },
});

const apiPort = axios.create({
  baseURL: env.ONLINE_ORDER_API_URL_PORT,
  headers: { 'content-type': 'application/x-www-form-urlencoded' }
});

api.interceptors.response.use(xmlParserMiddleware);
apiPort.interceptors.response.use(xmlParserMiddleware)

export class OnlineOrderingBaseApi {
  public api: AxiosInstance = api;
  public apiPort: AxiosInstance = apiPort
  public endpoints = endpoints.ONLINE;
}
