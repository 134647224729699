import React from 'react';
import { KeyboardInput } from '@components/Common';

type PropsType = {
  value: string;
  invalid: boolean;
  validationError?: string;
  onChange(value: string): void;
  onClickContinue(): void;
};

const PasswordStep: React.FC<PropsType> = ({
  value,
  invalid,
  onChange,
  validationError,
  onClickContinue,
}) => {
  return (
    <KeyboardInput
      value={value}
      label="Password"
      placeholder="Password"
      type="password"
      invalid={invalid || !!validationError}
      onChangeKeyInput={onChange}
      inputName="password"
      errorText={validationError}
      onClickContinue={onClickContinue}
    />
  );
};

export default React.memo(PasswordStep);
