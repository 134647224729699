import { ICategory } from '@interfaces/Category';

import styles from './CategoryItem.module.scss';

import promotionIcon from '@assets/promotion-img.png'

type PropsType = {
    category: ICategory;
    categoryId: number;
    onClick: (id: number) => void;
    type?: string
}

const CategoryItem: React.FC<PropsType> = ({ category, categoryId, onClick, type}) => {
    const { id, title } = category;

    const formatTitle = (title: string): string => {
        return title.charAt(0) + title.slice(1).toLowerCase()
    }
    
    return (
        <div className={`${styles.category_item} ${id === categoryId && styles.active}`} onClick={() => onClick(id)}>
            {type === 'promotion' && (<img src={promotionIcon} alt='promotion icon' />)}
            <span className={styles.category_title}>{formatTitle(title)}</span>
        </div>
    )
}


export default CategoryItem;