import { createAsyncThunk } from '@reduxjs/toolkit';

import memberApi from '@api/member.api';
import { ISignInPayload, ISignInResponse } from '@interfaces/SignIn';

export const signIn = createAsyncThunk<ISignInResponse, ISignInPayload>(
  'auth/validateUser',
  async (data) => {
    return await memberApi.validatePhone(data);
  }
);

export const validateMemberId = createAsyncThunk<{ memberId: string, firstName: string, phoneCell: string }, string>(
  'auth/validateMemberId',
  async (memberId: string) => {
    const { success, data } = await memberApi.validateMemberId(memberId);

    if (!success) {
      throw new Error('Request failed');
    }

    return data;
  }
);
