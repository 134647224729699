import { RootState } from '@store/store';

export const isPaymentMethodsEnabledSelector = (state: RootState) =>
  state.payment.isPaymentMethodsEnabled;

export const timeToPaySelector = (state: RootState) => state.payment.timeToPay;

export const isPaymentInProgressSelector = (state: RootState) => state.payment.isPaymentInProgress;

export const isPaymentSuccessSelector = (state: RootState) => state.payment.isPaymentSuccess;
