
import { ReactComponent as LemonImage } from '@assets/lemon.svg';

import styles from './Loader.module.scss';

const Loader: React.FC = () => {
    return (
        <div className={styles.loader_container}>
            <div className={styles.loader}>
                <div className={styles.loader_img_container}>    
                    <LemonImage />
                    <LemonImage />
                    <LemonImage />
                </div>
                <span className={styles.loader_title}>Loading...</span>
            </div>
        </div>
    )
}

export default Loader