import React from 'react';
import classNames from 'classnames';

import styles from './ProductSummary.module.scss';
import { getBase64ImageSrc } from '@utils/getBase64ImageSrc';
import { IOrderProduct } from '@interfaces/Order';

type PropsType = {
  product: IOrderProduct;
  className?: string;
};

const ProductSummary: React.FC<PropsType> = ({ product, className }) => {
  return (
    <div className={classNames(styles.product, className)}>
      <img
        className={styles.product__img}
        src={getBase64ImageSrc(product.imgSrc)}
        alt="Product image"
      />
      <h3 className={styles.product__title}>{product.title}</h3>
    </div>
  );
};

export default React.memo(ProductSummary);
