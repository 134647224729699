import React from 'react';
import { displayDollarsAmount } from '@utils/displayDollarsAmount';
import { calculateGiftCardBalance } from '@store/slices/member/member.utils';
import { IGiftCard } from '@interfaces/GiftCard';
import styles from './GiftCard.module.scss';
import giftIcon from '@assets/gift.png';

type PropsType = {
  card: IGiftCard;
  used: boolean;
  onUse(cardId: string): void;
  onUnUse(cardId: string): void;
};

const GiftCard: React.FC<PropsType> = ({ card, used, onUse, onUnUse }) => {
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <img src={giftIcon} alt="gift" />
        <h6>{card.cardNumber}</h6>
        <h5>{displayDollarsAmount(calculateGiftCardBalance(card))}</h5>
      </div>
      {used ? (
        <button onClick={() => onUnUse(card.cardNumber)} className={styles.used}>
          USED
        </button>
      ) : (
        <button onClick={() => onUse(card.cardNumber)}>USE</button>
      )}
    </div>
  );
};

export default React.memo(GiftCard);
