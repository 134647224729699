import styles from './ProductsLayout.module.scss';

type PropsType = {
    children: React.ReactNode;
  };
  

const ProductsLayout: React.FC<PropsType> = ({children}) => {
    return (
        <div className={styles.container}>
            {children}
        </div>
    )
}

export default ProductsLayout;