import React from 'react';

import styles from './RightSection.module.scss';
import { IGiftCard } from '@interfaces/GiftCard';
import { ITip } from '@interfaces/Tips';

import { Button } from '@components/Common';
import { GiftCardsContainer, OrderSummary } from '@components/CheckOut';

import cardIcon from '@assets/credit_score.svg'

type PropsType = {
  tips: ITip[];
  onChangeTip(value: number): void;
  subtotal: number;
  cardPay: number;
  maxTipAmount: number;
  tipsAmount: number | null;
  total: number;
  taxes: number;
  giftCards: IGiftCard[];
  selectedCardsIds: string[];
  onAddNewCard(cardNumber: string, pin: string): void;
  onUseGiftCard(cardId: string): void;
  onUnUseGiftCard(cardId: string): void;
  onPay(): void;
  bonusValue: number;
  hasErrorGiftCard: string;
  handleClearError(): void;
  isAuthorized: boolean;
};

const RightSection: React.FC<PropsType> = ({
  // tips,
  // onChangeTip,
  subtotal,
  // maxTipAmount,
  giftCards,
  onAddNewCard,
  onUseGiftCard,
  onUnUseGiftCard,
  onPay,
  selectedCardsIds,
  total,
  tipsAmount,
  bonusValue,
  taxes,
  hasErrorGiftCard,
  handleClearError,
  cardPay,
  isAuthorized,
}) => {
  return (
    <div className={styles.container}>
      <div>
        {/* <TipsSelect
          tips={tips}
          onChangeTip={onChangeTip}
          maxTipAmount={maxTipAmount}
          tipsAmount={tipsAmount}
        /> */}
        {isAuthorized && (
          <GiftCardsContainer
            giftCards={giftCards}
            onAddNewCard={onAddNewCard}
            onUseGiftCard={onUseGiftCard}
            onUnUseGiftCard={onUnUseGiftCard}
            selectedCardsIds={selectedCardsIds}
            hasErrorGiftCard={hasErrorGiftCard}
            handleClearError={handleClearError}
          />
        )}
        <OrderSummary
          subtotal={subtotal}
          tipsAmount={tipsAmount}
          total={total}
          bonusValue={bonusValue}
          taxes={taxes}
          cardPay={cardPay}
        />
      </div>
      <Button id={styles.payBtn} onClick={onPay}>
        <img src={cardIcon} alt="" />
        Pay
      </Button>
    </div>
  );
};

export default React.memo(RightSection);
