import { IProduct } from '@interfaces/Product';

export const findKeyByItemId = (object: Record<number, IProduct[]>, itemId: number | undefined) => {
    if (!itemId) return undefined
    for (const [key, items] of Object.entries(object)) {
      for (const item of items) {
        if (item.id === itemId) {
          return +key;
        }
      }
    }
    return undefined;
  };