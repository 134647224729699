import { ISavedShopInfo } from '@interfaces/ShopInfo';
import ls from '@utils/ls';
import storage from '@config/storage';

export const saveShopDataToLocalStorage = (shopInfo: ISavedShopInfo) => {
  ls.set(storage.LS.SHARED_POINTER_ID, shopInfo.shared_pointer_id)
  ls.set(storage.LS.CLOVER_API_TOKEN, shopInfo.clover_api_token);
  ls.set(storage.LS.CLOVER_DEVICE_ID, shopInfo.clover_device_id);
  ls.set(storage.LS.POS_ID, shopInfo.pos_id);
  ls.set(storage.LS.CLOVER_IP, shopInfo.clover_pay_url);
  ls.set(storage.LS.TRANSACTION_IP, shopInfo.upload_trans_url);
  ls.set(storage.LS.SHOP_ID, shopInfo.id);
  ls.set(storage.LS.PRICE_LEVEL_KIOSK, shopInfo.pricelevel_kiosk);
  ls.set(storage.LS.REGION_ID, shopInfo.region_id);
  ls.set(storage.LS.DEVICE_ID, shopInfo.deviceID);
  ls.set(storage.LS.ENCRYPTION_CODE, shopInfo.encryptionCode);
};

export const getShopDataFromLocalStorage = (): ISavedShopInfo | null => {
  const shopId = ls.get<string>(storage.LS.SHOP_ID);
  const priceLevel = ls.get<string>(storage.LS.PRICE_LEVEL_KIOSK);
  const regionId = ls.get(storage.LS.REGION_ID);
  const deviceID = ls.get<string>(storage.LS.DEVICE_ID);
  const encryptionCode = ls.get<string>(storage.LS.ENCRYPTION_CODE);
  const apiToken = ls.get<string>(storage.LS.CLOVER_API_TOKEN);
  const cloverDeviceId = ls.get<string>(storage.LS.CLOVER_DEVICE_ID);
  const posId = ls.get<string>(storage.LS.POS_ID);
  const cloverIP = ls.get<string>(storage.LS.CLOVER_IP);
  const transactionIP = ls.get<string>(storage.LS.TRANSACTION_IP);
  const sharedPointerId = ls.get<string>(storage.LS.SHARED_POINTER_ID);

  if (
    !shopId ||
    !priceLevel ||
    !regionId ||
    !deviceID ||
    !encryptionCode ||
    !apiToken ||
    !cloverDeviceId ||
    !posId ||
    !cloverIP ||
    !transactionIP ||
    !sharedPointerId
  ) {
    return null;
  }

  return {
    id: shopId,
    pricelevel_kiosk: +priceLevel,
    region_id: regionId,
    clover_api_token: apiToken,
    clover_device_id: cloverDeviceId,
    pos_id: posId,
    clover_pay_url: cloverIP,
    upload_trans_url: transactionIP,
    encryptionCode,
    deviceID,
    shared_pointer_id: sharedPointerId,
  };
};
