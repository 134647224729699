import React from 'react';
import classNames from 'classnames';

import styles from './CloseButton.module.scss';
import closeIcon from '@assets/closeIcon.svg';

type PropsType = {
  onClick?: () => void;
  className?: string;
};

const CloseButton: React.FC<PropsType> = ({ onClick, className }) => {
  const handleClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (onClick) {
      onClick();
    }
  };

  return (
    <div className={classNames(styles.button, className)} onClick={handleClick}>
      <img src={closeIcon} alt="close" />
    </div>
  );
};

export default React.memo(CloseButton);
