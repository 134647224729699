import { ProductVariantsEnum } from '@interfaces/enums';

export interface IProduct {
  id: number;
  title: Partial<Record<ProductVariantsEnum, string>>;
  imgSrc: string;
  price: Partial<Record<ProductVariantsEnum, number>>;
  vat: Partial<Record<ProductVariantsEnum, number>>;
  type: ProductTypeEnum;
  variants: ProductVariantsEnum[];
  variantsIds: Partial<Record<ProductVariantsEnum, number>>;
  invalid_end: number;
  invalid_start: number;
  group_letter_id: number[]
}

export enum ProductTypeEnum {
  Waffle,
  Drink,
}
