import React from 'react';

import styles from './Header.module.scss';

import headerIcon from '@assets/happyLomonLogo.png';
import pointsIcon from '@assets/happyPointsIcon.png';

type PropsType = {
  isAuthorized: boolean;
  pointsBalance: number;
};

const Header: React.FC<PropsType> = ({ pointsBalance, isAuthorized }) => {
  return (
    <div className={styles.container}>
      <img src={headerIcon} alt="happy lemon logo" />
      {isAuthorized && (
        <button className={styles.pointBalance}>
          <img src={pointsIcon} alt="balance" />
          <p>{pointsBalance}</p>
        </button>
      )}
    </div>
  );
};

export default React.memo(Header);
