import React from 'react';

import { store } from '@store/store';

import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

const Providers: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <BrowserRouter>
      <ReduxProvider store={store}>{children}</ReduxProvider>
    </BrowserRouter>
  );
};

export default React.memo(Providers);
