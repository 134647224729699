import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from './redux';

import { clearAuth } from '@store/slices/auth/auth.slice';
import { clearMember, setPhoneNumber } from '@store/slices/member/member.slice';
import { clearOrder } from '@store/slices/order/order.slice';

import routes from '@config/routes';

export const useStartOver = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return useCallback(async () => {
    await navigate(routes.HOME);
    dispatch(clearAuth());
    dispatch(clearMember());
    dispatch(clearOrder());
    dispatch(setPhoneNumber(null));
  }, [navigate, dispatch]);
};
