import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './Button.module.scss';

type PropsType = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'primary' | 'secondary';
  size?: 'small' | 'big';
  className?: string;
};

const Button: React.FC<PropsType> = ({
  children,
  variant = 'primary',
  size = 'small',
  className,
  ...rest
}) => {
  const buttonClass = classNames(
    styles.button,
    {
      [styles.primary]: variant === 'primary',
      [styles.secondary]: variant === 'secondary',
      [styles.small]: size === 'small',
      [styles.big]: size === 'big',
    },
    className
  );

  return (
    <button className={classNames(buttonClass, className)} {...rest}>
      {children}
    </button>
  );
};

export default React.memo(Button);
