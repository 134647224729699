import React from 'react';
import { IOrderView } from '@interfaces/Order';

import styles from './LeftSection.module.scss';
import { ProductList } from '@components/CheckOut';
import { Button } from '@components/Common';

import backSpaceIcon from '@assets/keyboard_backspace.svg'
import syncIcon from '@assets/sync.svg'
import loginIcon from '@assets/person.svg'


type PropsType = {
  productsList: IOrderView[];
  productsRewardId: number[];
  onUseBonuses(orderId: string, value: number): void;
  onUnUseBonuses(orderId: string): void;
  onRemoveOrder(orderId: string): void;
  onUpdateOrder(orderId: string): void;
  appliedBonuses: Record<string, number>;
  isAuthorized: boolean;
  handleBack(): void;
  handleStartOver(): void;
  pointsBalance: number;
  isPromoActive: boolean;
  onLogin(): void;
};

const LeftSection: React.FC<PropsType> = ({
  productsList,
  productsRewardId,
  onUseBonuses,
  onUnUseBonuses,
  appliedBonuses,
  isAuthorized,
  handleBack,
  handleStartOver,
  pointsBalance,
  onRemoveOrder,
  onUpdateOrder,
  isPromoActive,
  onLogin
}) => {
  return (
    <div className={styles.container}>
      <ProductList
        productsList={productsList}
        productsRewardId={productsRewardId}
        onUseBonuses={onUseBonuses}
        onUnUseBonuses={onUnUseBonuses}
        appliedBonuses={appliedBonuses}
        isAuthorized={isAuthorized}
        pointsBalance={pointsBalance}
        onRemoveOrder={onRemoveOrder}
        onUpdateOrder={onUpdateOrder}
        isPromoActive={isPromoActive}
      />
      <div className={styles.buttonGroup}>
        <Button variant='secondary' onClick={handleStartOver} className={styles.btn}>
          <img src={syncIcon} alt="" />
          Start Over
        </Button>
        <Button variant="secondary" onClick={handleBack} className={styles.btn}>
         <img src={backSpaceIcon} alt="" />
          Go Back
        </Button>
        {!isAuthorized && (
          <Button className={styles.btn} onClick={onLogin} >
            <img src={loginIcon} alt="" />
            Sign In
          </Button>
        )}
      </div>
    </div>
  );
};

export default React.memo(LeftSection);
