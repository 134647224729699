export default {
  ONLINE_ORDER_API_URL: process.env.REACT_APP_ONLINE_ORDER_API_URL,
  ONLINE_ORDER_API_URL_PORT: process.env.REACT_APP_ONLINE_ORDER_API_URL_PORT,
  MEMBERSHIP_API_TOKEN: process.env.REACT_APP_MEMBERSHIP_API_TOKEN,
  MEMBERSHIP_API_URL: process.env.REACT_APP_MEMBERSHIP_API_URL,
  ORDER_URL: process.env.REACT_APP_ORDER_URL,
  ORDER_PROD_URL: process.env.REACT_APP_PROD_ORDER_URL,
  ORDER_TOKEN: process.env.REACT_APP_ORDER_TOKEN,
  VERSION: process.env.REACT_APP_VERSION,
  ENV: window?.env?.ENV || 'dev',
  COUNT: window?.env?.COUNT
};
