import React from 'react';

import { IOrderView } from '@interfaces/Order';
import cartIcon from '@assets/cartIcon.svg';
import styles from './CartModal.module.scss';
import { displayDollarsAmount } from '@utils/displayDollarsAmount';

import { SideModalLayout } from '@components/Layout';
import ProductCartItem from '@components/Products/ProductCartItem/ProductCartItem';
import { Button, PromoCard } from '@components/Common';
import { PromoCardEnum } from '@components/Common/PromoCard/PromoCard';

type PropsType = {
  onClose(): void;
  onRemove(orderId: string): void;
  onChangeQuantity(orderId: string, value: number): void;
  selectedProductsList: IOrderView[];
  onCheckout(): void;
  onUpdate(orderId: string): void;
  subtotal: number;
};

const CartModal: React.FC<PropsType> = ({
  onClose,
  selectedProductsList,
  onRemove,
  onChangeQuantity,
  onCheckout,
  onUpdate,
  subtotal,
}) => {
  const renderContent = () => {
    if (!selectedProductsList.length) {
      return (
        <div className={styles.emptyCart}>
          <img src={cartIcon} alt="cart" />
          <h3>Your cart is empty!</h3>
          <h4>Add products you like to proceed to checkout.</h4>
        </div>
      );
    }

    return (
      <div className={styles.content}>
        {selectedProductsList.map((order) => (
          <>
            <ProductCartItem
              key={order.id}
              order={order}
              onRemove={onRemove}
              onUpdate={onUpdate}
              onChangeQuantity={onChangeQuantity}
            />
            {order.promo && order.promo.promoAction !== 2 && <PromoCard type={PromoCardEnum.menu} promo={order.promo}/>}
          </>
        ))}
      </div>
    );
  };

  return (
    <SideModalLayout title="Cart" onClose={onClose}>
      <div className={styles.container}>
        {renderContent()}
        <Button
          className={styles.checkout}
          onClick={onCheckout}
          disabled={!selectedProductsList.length}
        >
          Checkout: Subtotal {displayDollarsAmount(subtotal)}
        </Button>
      </div>
    </SideModalLayout>
  );
};

export default React.memo(CartModal);
