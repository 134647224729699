import React, { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { NumberParam } from 'serialize-query-params';

import { useQueryState } from '@hooks/useQueryParam';
import routes from '@config/routes';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useStartOver } from '@hooks/useStartOver';
import { shopCategoriesSelector } from '@store/slices/categories/categories.selectors';
import { getCategories } from '@store/slices/categories/categories.thunk';
import { shopSelector } from '@store/slices/shop/shop.selectors';
import { getProductFromBarcode, getProductsData } from '@store/slices/products/products.thunk';
import { shopProductsSelector } from '@store/slices/products/products.selectors';
import { findKeyByItemId } from '@utils/findKeyByItemId';
import {
  availablePromoSelector,
  isPromoActiveSelector,
  orderProductsViewSelector,
  ordersCountSelector,
  promotionsAmountValue,
  promotionsProductsSelector,
  promotionsSelector,
  subtotalWithBonusesSelector,
} from '@store/slices/order/order.selector';
import { changeProductQuantity, checkIfPromoIsAvailable, clearPromo, removeOrder } from '@store/slices/order/order.slice';
import { isAuthorizedSelector } from '@store/slices/auth/auth.selectors';
import { phoneNumberSelector } from '@store/slices/member/member.selector';
import { getShopPromotions } from '@store/slices/order/order.thunk';
import { ModeType, useSelectProductModal } from '@hooks/useSelectProductModal';

import styles from './Products.module.scss';

import {
  ProductsLayout,
  SideBar,
  CategoriesList,
  ProductsList,
  SelectProductModal,
  DoYouWantToSignInModal,
  CartModal,
  CartButton,
  ScanModal,
  Promotions,
} from '@components/Products';
import { Button } from '@components/Common';
import PromotionButton from '@components/Common/PromotionButton/PromotionButton';
import PromoModal from '@components/Common/PromoModal/PromoModal';
import ProposeModal from '@components/Products/ProposeModal/ProposeModal';

import scanIcon from '@assets/qr_code_scanner.svg'
import syncIcon from '@assets/sync.svg'
import loginIcon from '@assets/person.svg'

const Products: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useAppSelector(shopSelector);
  const products = useAppSelector(shopProductsSelector);
  const categories = useAppSelector(shopCategoriesSelector);
  const isAuthorized = useAppSelector(isAuthorizedSelector);
  const savedPhoneNumber = useAppSelector(phoneNumberSelector);
  const promoProducts = useAppSelector(promotionsProductsSelector);
  const promo = useAppSelector(promotionsSelector);
  const availablePromo = useAppSelector(availablePromoSelector)
  const [isOpenSignInModal, setIsOpenSignInModal] = useState(false);
  const [isPromoModal, setIsPromoModal] = useState(false)
  const [isOpenScanModal, setIsOpenScanModal] = useState(false);
  const selectedProductsList = useAppSelector(orderProductsViewSelector);
  const ordersCount = useAppSelector(ordersCountSelector);
  const [isOpenCart, setIsOpenCart] = useState(false);
  const [isOpenPromoModal, setIsOpenPromoModal] = useState(false)
  const [isPromoVisible, setIsPromoVisible] = useState(false)
  const subtotal = useAppSelector(subtotalWithBonusesSelector);
  const promoAmountValue = useAppSelector(promotionsAmountValue)
  const isPromoAvtive = useAppSelector(isPromoActiveSelector)
  const [promotion, setPromotion] = useState({
    id: 0,
    title: '',
  });

  const [categoryId, setCategoryId] = useQueryState('categoryId', NumberParam, 0);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        await dispatch(getCategories());
        await dispatch(getProductsData());
        await dispatch(getShopPromotions());
      }
    };

    fetchData();
  }, [id]);

  // useEffect(() => {
  //   if (categories.length !== 0) {
  //     setCategoryId(categories[0]?.id);
  //   }
  // }, [categories]);

  useEffect(() => {
    if (availablePromo.length) {
      setIsPromoVisible(true)
    } else {
      setIsPromoVisible(false)
    }
  }, [availablePromo])


  const handleRestart = useStartOver();

  const handleSelectProductModalClose = (mode: ModeType) => {
    if (mode === 'update') {
      setIsOpenCart(true);
    }
  };

  const {
    props: selectProductModalProps,
    isOpen: isSelectProductModalOpen,
    openSelectProductModalInAddMode,
    openSelectProductModalInUpdateMode,
  } = useSelectProductModal({
    categoryId,
    products,
    onClose: handleSelectProductModalClose,
  });

  const handleCategoryClick = (id: number) => {
    setCategoryId(id);
    window.scrollTo(0, 0);
  };

  const onCloseCart = () => {
    setIsOpenCart(false);
  };

  const onTogglePromoModal = () => {
    if (availablePromo.length === 0) return
    setIsOpenPromoModal(!isOpenPromoModal)
  }

  const onRemoveOrder = (orderId: string) => {
    dispatch(removeOrder(orderId));
    dispatch(checkIfPromoIsAvailable())
  };

  const handleUpdate = (orderId: string) => {
    openSelectProductModalInUpdateMode(orderId);
    onCloseCart();
  };

  const onChangeQuantity = (orderId: string, value: number) => {
    dispatch(changeProductQuantity({ orderId, value }));
    dispatch(checkIfPromoIsAvailable())
  };

  const handleLogin = () => {
    navigate({
      pathname: routes.SIGN_IN,
      search: '?tab=0',
    });
  };

  const handleLoginFromCard = () => {
    navigate({
      pathname: routes.SIGN_IN,
      search: '?tab=0&card=true',
    });
  };

  const handleLoginAsGuest = () => {
    dispatch(clearPromo())
    navigate({
      pathname: routes.CHECK_OUT,
    });
  };

  const handleCloseScanModal = () => {
    setIsOpenScanModal(false);
  };

  const handlePromoSelect = (id: number, title: string) => {
    if (!isAuthorized) {
      setIsOpenSignInModal(true)
      return
    }

    setPromotion({
      title,
      id,
    });
  };

  const handlePromoClear = () => {
    setPromotion({
      id: 0,
      title: '',
    })
  }

  const handleCheckScanBarcode = async (barcode: string) => {
    await dispatch(getProductFromBarcode(barcode))
      .then(unwrapResult)
      .then((result: number) => {
        setCategoryId(findKeyByItemId(products, result));
        openSelectProductModalInAddMode(result);
        handleCloseScanModal();
      });
  };

  const handleCheckout = () => {
    if (isAuthorized || typeof savedPhoneNumber === 'number') {
      navigate(routes.CHECK_OUT);
    }
    if (isPromoAvtive) {
      setIsPromoModal(true)
    }

    if (!isAuthorized && !isPromoAvtive) {
      navigate(routes.CHECK_OUT);
    }
  };

  const onCartClick = () => {
    setIsOpenCart(true);
  };

  const renderSelectProductModal = () => {
    if (!isSelectProductModalOpen) {
      return null;
    }

    return <SelectProductModal {...selectProductModalProps} />;
  };

  return (
    <ProductsLayout>
      <SideBar>
        <CategoriesList
          categories={categories}
          categoryId={categoryId}
          handleCategoryClick={handleCategoryClick}
          handlePromotionClick={() => {}}
        />
      </SideBar>
      {categoryId === 0 ? (
        <Promotions
          products={promoProducts[promotion.id]}
          promo={promo}
          onSelectProduct={openSelectProductModalInAddMode}
          selectedPromotion={promotion}
          onSelectPromo={handlePromoSelect}
          onClearPromo={handlePromoClear}
        />
      ) : (
        <ProductsList
          products={products[categoryId]}
          onSelectProduct={openSelectProductModalInAddMode}
        />
      )}
      <div className={styles.btn}>
        <CartButton onClick={onCartClick} value={ordersCount} />
        {isPromoVisible && <PromotionButton onOpen={onTogglePromoModal} />}
      </div>
      {renderSelectProductModal()}
      {isOpenSignInModal && (
        <ProposeModal
          onLogin={handleLogin}
          handleLoginAsGuest={handleLoginAsGuest}
        />
      )}

      {isPromoModal && (
        <DoYouWantToSignInModal 
          onLogin={handleLoginFromCard}
          handleLoginAsGuest={handleLoginAsGuest}
        />
      )}
      {isOpenCart && (
        <CartModal
          onClose={onCloseCart}
          selectedProductsList={selectedProductsList}
          subtotal={subtotal - promoAmountValue}
          onRemove={onRemoveOrder}
          onChangeQuantity={onChangeQuantity}
          onCheckout={handleCheckout}
          onUpdate={handleUpdate}
        />
      )}
      {isOpenScanModal && (
        <ScanModal onClose={handleCloseScanModal} handleCheckBarcode={handleCheckScanBarcode} />
      )}
      {isOpenPromoModal && <PromoModal promo={promo} promoId={availablePromo[availablePromo.length - 1]} onClose={onTogglePromoModal} />}
      <div className={styles.buttons}>
        <Button
          className={styles.scan_here}
          variant="secondary"
          onClick={() => {
            setIsOpenScanModal(true);
          }}
        >
          <img src={scanIcon} alt="" />
          Scan Here
        </Button>
        <Button variant="secondary" className={styles.start_over} onClick={handleRestart}>
          <img src={syncIcon} alt="" />
          Start Over
        </Button>
        {!isAuthorized && (
          <Button className={styles.login} onClick={handleLogin}>
            <img src={loginIcon} alt="" />
            Sign In
          </Button>
        )}
      </div>
    </ProductsLayout>
  );
};

export default Products;
