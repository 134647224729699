import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useStartOver } from '@hooks/useStartOver';
import {
  cancelPayment,
  checkPaymentMethods,
  startPayment,
  startPaymentWithMember,
} from '@store/slices/payment/payment.thunks';
import { clearIsSaccess } from '@store/slices/payment/payment.slice';
import {
  cardPayValueSelector,
  promotionsAmountValue,
  subtotalSelector,
  subtotalWithBonusesSelector,
  taxesSelector,
  tipValueSelector,
  totalSelector,
} from '@store/slices/order/order.selector';
import {
  isPaymentInProgressSelector,
  isPaymentMethodsEnabledSelector,
  isPaymentSuccessSelector,
  timeToPaySelector,
} from '@store/slices/payment/payment.selectors';
import styles from './Payment.module.scss';
import routes from '@config/routes';

import { PaymentBody, PaymentFooter, PaymentHeader, PaymentLayout } from '@components/Payment';
import { isAuthorizedSelector } from '@store/slices/auth/auth.selectors';
import { setTip } from '@store/slices/order/order.slice';

const Payment = () => {
  const total = useAppSelector(totalSelector);
  const cardPay = useAppSelector(cardPayValueSelector);
  const tips = useAppSelector(tipValueSelector);
  const subtotal = useAppSelector(subtotalSelector);
  const promoAmountValue = useAppSelector(promotionsAmountValue)
  const subtotalWithBonuses = useAppSelector(subtotalWithBonusesSelector);
  const isAuthorized = useAppSelector(isAuthorizedSelector);
  const taxes = useAppSelector(taxesSelector);
  const isPaymentMethodsEnabled = useAppSelector(isPaymentMethodsEnabledSelector);
  const isPaymentInProgress = useAppSelector(isPaymentInProgressSelector);
  const isPaymentSuccess = useAppSelector(isPaymentSuccessSelector);
  const timeToPay = useAppSelector(timeToPaySelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const abortControllerRef = useRef<AbortController | null>(null);

  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  const [countdown, setCountdown] = useState(timeToPay);

  useEffect(() => {
    dispatch(checkPaymentMethods());

    return () => {
      if (intervalRef.current) {
        clearTimeout(intervalRef.current);
      }
      discardPayment();
    };
  }, []);

  const initializeCountdown = () => {
    intervalRef.current = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);
  };

  const stopCountdown = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const startToPay = () => {
    initializeCountdown();

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    try {
      isAuthorized
        ? dispatch(startPaymentWithMember({
          signal: abortController.signal,
          onSuccess: handleSuccess
        }))
        : dispatch(startPayment({
          signal: abortController.signal,
          onSuccess: handleSuccess
        }));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const discardPayment = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    if (isPaymentInProgress) {
      dispatch(cancelPayment());
      dispatch(setTip(0))
      stopCountdown();
    }
  };

  useEffect(() => {
    if (isPaymentMethodsEnabled) {
      startToPay();
    } else {
      discardPayment();
    }
  }, [isPaymentMethodsEnabled]);

  useEffect(() => {
    if (countdown < 1) {
      discardPayment();
    }
  }, [countdown]);

  const handleGoBack = () => {
    dispatch(clearIsSaccess());
    navigate(routes.CHECK_OUT);
  };

  const handleSuccess = useStartOver();

  return (
    <PaymentLayout>
      <div className={styles.content}>
        <PaymentHeader
          total={total - promoAmountValue}
          taxes={taxes}
          subtotal={isAuthorized ? subtotalWithBonuses - promoAmountValue : subtotal - promoAmountValue}
          tips={tips ?? 0}
          cardPay={cardPay}
        />
        <PaymentBody countdown={countdown} isPaymentSuccess={isPaymentSuccess} total={total} />
        <PaymentFooter
          isPaymentSuccess={isPaymentSuccess}
          isPaymentInProgress={isPaymentInProgress}
          onBreak={discardPayment}
          onGoBack={handleGoBack}
          onSuccsess={handleSuccess}
        />
      </div>
    </PaymentLayout>
  );
};

export default Payment;
