/* eslint no-redeclare: 0 */
import { useQueryParam, QueryParamOptions, withDefault } from 'use-query-params';
import { QueryParamConfig } from 'serialize-query-params';
import { useMemo } from 'react';

type NewValueType<D> = D | undefined | null | ((latestValue: D) => D | undefined | null);
export type UpdateQueryAction<TypeToEncode> = (newValue?: NewValueType<TypeToEncode>) => void;

const options: QueryParamOptions = {
  removeDefaultsFromUrl: true,
};

export function useQueryState<TypeToEncode>(
  key: string,
  param: QueryParamConfig<any>
): [TypeToEncode | null, UpdateQueryAction<TypeToEncode>];

export function useQueryState<TypeToEncode>(
  key: string,
  param: QueryParamConfig<any>,
  initialValue: TypeToEncode
): [TypeToEncode, UpdateQueryAction<TypeToEncode>];

export function useQueryState<TypeToEncode>(
  key: string,
  param: QueryParamConfig<any>,
  initialValue: TypeToEncode | null = null
) {
  const paramWithDefault = useMemo(() => {
    if (initialValue) {
      return withDefault<TypeToEncode, TypeToEncode>(param, initialValue);
    }

    return param;
  }, []);

  const [query, setQuery] = useQueryParam<TypeToEncode>(key, paramWithDefault, options);

  const value = useMemo(() => {
    if (Array.isArray(query)) {
      return query.filter(Boolean);
    }

    return query || initialValue;
  }, [query]);

  return [value, setQuery];
}