import React from 'react';

import styles from 'components/Common/NumKeyboard/NumKeyboard.module.scss';

import deleteIcon from '@assets/deleteIcon.png';

const numbers = Array.from({ length: 9 }, (_, i) => i + 1);

type PropsType = {
  handleButtonClick(num: number): void;
  handleDelete(): void;
  handleDotClick?(): void;
  disabledDot?: boolean;
};

const NumKeyboard: React.FC<PropsType> = ({
  handleDelete,
  handleButtonClick,
  handleDotClick,
  disabledDot,
}) => {
  return (
    <div className={styles.keyboard}>
      {numbers.map((num, index) => (
        <button key={index} onClick={() => handleButtonClick(num)}>
          {num}
        </button>
      ))}
      <button onClick={handleDotClick}>{disabledDot ? '' : ','}</button>
      <button onClick={() => handleButtonClick(0)}>0</button>
      <button onClick={handleDelete}>
        <img src={deleteIcon} alt="delete" />
      </button>
    </div>
  );
};

export default React.memo(NumKeyboard);
