import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import scanImage from '@assets/membershipCardImage.png';
import styles from './ScanCardStep.module.scss';
import { Toast } from '@components/Common';
import { debounce } from 'lodash';

type PropsType = {
  handleCheckScannedCard(memberId: string): void;
  handleClearError(): void;
  hasError: boolean;
};

const ScanCardStep: React.FC<PropsType> = ({
  handleCheckScannedCard,
  hasError,
  handleClearError,
}) => {
  const [memberId, setMemberId] = useState('');

  useEffect(() => {
    if (hasError) {
      setMemberId('');
    }
  }, [hasError]);

  const handleCheckScannedCardDebounced = useMemo(() => {
    return debounce(handleCheckScannedCard, 1000);
  }, []);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    handleCheckScannedCardDebounced(value);
    setMemberId(value);
  };

  return (
    <div className={styles.container}>
      <h5 className={styles.title}>Scan Membership Card</h5>
      <img src={scanImage} alt="membership qr" />
      <input
        autoFocus
        type="password"
        value={memberId}
        onChange={onChange}
        placeholder="Member ID"
        className={classNames([hasError && styles.hasError])}
      />
      {hasError && (
        <Toast title="Member ID not found" subtitle="Please try again" onClose={handleClearError} />
      )}
    </div>
  );
};

export default React.memo(ScanCardStep);
