import { OnlineOrderingBaseApi } from './online-ordering-base.api';


type IRewardList = {
    key_id: string;
    is_reward: number;
}

interface IDataRequest {
    item_list: number[];
    reward_list: IRewardList[];
}


class RewardApi extends OnlineOrderingBaseApi {
    async getInformation(dataRequest: IDataRequest) {
        const data = JSON.stringify(dataRequest)

        return this.apiPort.post(this.endpoints.REWARD_INFORMATION, {data} )
    }

    async payByGiftCard(giftCard: any) {
        return this.apiPort.post(this.endpoints.PAY_BY_GIFT_CARD, giftCard)
    }
}

export default new RewardApi()