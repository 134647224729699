import React from 'react';
import { displayDollarsAmount } from '@utils/displayDollarsAmount';
import styles from './OrderSummary.module.scss';
import lemonIcon from '@assets/happyPointsIcon.png';

type PropsType = {
  subtotal: number;
  tipsAmount: number | null;
  total: number;
  taxes: number;
  bonusValue: number;
  cardPay: number;
};

const OrderSummary: React.FC<PropsType> = ({
  subtotal,
  tipsAmount,
  total,
  bonusValue,
  taxes,
  cardPay,
}) => {
  return (
    <div className={styles.container}>
      {!!bonusValue && (
        <div className={styles.info}>
          <h4>Bonuses</h4>
          <div className={styles.bonuses}>
            <img src={lemonIcon} alt="lemon" />
            <h5>-{bonusValue}</h5>
          </div>
        </div>
      )}
      <div className={styles.info}>
        <h4>Subtotal</h4>
        <h3>${subtotal.toFixed(2)}</h3>
      </div>
      {typeof tipsAmount === 'number' && (
        <div className={styles.info}>
          <h4>Tips</h4>
          <h3>${tipsAmount.toFixed(2)}</h3>
        </div>
      )}
      <div className={styles.info}>
        <h4>Taxes & Fees</h4>
        <h3>${taxes.toFixed(2)}</h3>
      </div>
      {!!cardPay && (
        <div className={styles.info}>
          <h4>Gift Card</h4>
          <h3>-{displayDollarsAmount(cardPay)}</h3>
        </div>
      )}
      <div className={styles.divider} />
      <div className={styles.info}>
        <h4>Total</h4>
        <h3>${total.toFixed(2)}</h3>
      </div>
    </div>
  );
};

export default React.memo(OrderSummary);
