import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { NumberParam, StringParam } from 'serialize-query-params';
import { useQueryState } from '@hooks/useQueryParam';
import routes from '@config/routes';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { validateMemberId, signIn } from '@store/slices/auth/auth.thunk';
import { setIsError } from '@store/slices/auth/auth.slice';
import { hasErrorSelector, isAuthorizedSelector } from '@store/slices/auth/auth.selectors';
import { setPhoneNumber } from '@store/slices/member/member.slice';
import { required, validateEmail, validatePhoneNumber } from '@utils/validators';
import { isPhoneNumber } from '@utils/isPhoneNumber';

import styles from './SignIn.module.scss';

// import { PropsType as PhoneType } from '@components/SignIn/PhoneNumberTab/PhoneNumberTab';
import { PropsType as MemberType } from '@components/SignIn/MembershipCardTab/MembershipCardTab';

import { Button, Tabs } from '@components/Common';
import { BaseLayout } from '@components/Layout';
import { MembershipCardTab, PhoneModal } from '@components/SignIn';
import { clearPromo } from '@store/slices/order/order.slice';
// import { PhoneNumberTab } from '@components/SignIn';

const tabs = [
  // {
  //   text: 'Phone Number',
  //   content: (props: PhoneType) => <PhoneNumberTab {...props} />,
  // },
  {
    text: 'Membership Card',
    content: (props: MemberType) => <MembershipCardTab {...props} />,
  },
];

const SignIn = () => {
  const [step, setStep] = useState<'accountId' | 'password'>('accountId');
  const location = useLocation();
  const navigate = useNavigate();
  const hasError = useAppSelector(hasErrorSelector);
  const isAuthorized = useAppSelector(isAuthorizedSelector);
  const dispatch = useAppDispatch();
  const [accountId, setAccountId] = useState('');
  const [password, setPassword] = useState('');
  const [tab, setTab] = useQueryState('tab', NumberParam, 0);
  const [card] = useQueryState('card', StringParam)
  const [validationError, setValidationError] = useState<string>('');
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(!!location?.state?.phoneModal ?? false);
  
  useEffect(() => {
    if (isAuthorized) {
      card ? navigate(routes.CHECK_OUT) : navigate(-1);
    }
  }, [isAuthorized]);

  useEffect(() => {
    if (validationError) {
      setValidationError('');
    }
  }, [accountId, password]);

  const setNewTab = (index: number) => {
    setTab(index);
    dispatch(setIsError(false));
    setStep('accountId');
    setAccountId('');
    setPassword('');
  };

  const validateAccountId = () => {
    let error = required(accountId);

    if (error) {
      setValidationError(error);

      return false;
    }

    const isPhone = isPhoneNumber(accountId);

    if (isPhone) {
      error = validatePhoneNumber(accountId);

      if (error) {
        setValidationError(error);

        return false;
      }
    } else {
      error = validateEmail(accountId);

      if (error) {
        setValidationError(error);

        return false;
      }
    }

    return true;
  };

  const validatePassword = () => {
    const error = required(password);

    if (error) {
      setValidationError(error);

      return false;
    }

    return true;
  };

  const handleContinue = () => {
    setValidationError('');

    if (step !== 'password') {
      const isValidAccountId = validateAccountId();

      if (isValidAccountId) {
        setStep('password');
      }

      return null;
    }

    const isValidPassword = validatePassword();

    if (isValidPassword) {
      dispatch(signIn({ number: accountId, password }));
    }
  };

  const handleBack = () => {
    setValidationError('');

    if (step === 'password') {
      setStep('accountId');
    } else {
      navigate(routes.PRODUCT_LIST);
    }
  };

  const handleCheckScannedCard = (memberId: string) => {
    dispatch(validateMemberId(memberId));
  };

  const handleClearError = () => {
    dispatch(setIsError(false));
  };

  const handleContinueAsGuest = () => {
    navigate(routes.CHECK_OUT);
    dispatch(clearPromo())
    // const isPhoneNumber = !validatePhoneNumber(accountId);

    // if (isPhoneNumber) {
    //   dispatch(setPhoneNumber(+accountId));
    //   return;
    // }

    // setIsPhoneModalOpen(true);
  };

  const handleConfirmPhone = (num: string) => {
    const error = validatePhoneNumber(num);

    if (error) {
      setValidationError(error);

      return null;
    }

    dispatch(setPhoneNumber(+num));
    navigate(routes.CHECK_OUT);
  };

  const handleClosePhoneModal = () => {
    setValidationError('');

    if (location?.state?.phoneModal) {
      navigate(routes.PRODUCT_LIST);
    } else {
      setIsPhoneModalOpen(false);
    }
  };

  const renderButtons = () => {
    return (
      <div
        className={classNames([
          styles.buttonGroup,
          tab !== 0 && step === 'accountId' && styles.membership,
        ])}
      >
        <Button id="back" variant="secondary" onClick={handleBack}>
          Go Back
        </Button>
        <Button id={styles.continue} size="big" onClick={handleContinue}>
          Continue
        </Button>
        <Button id="guest" variant="secondary" onClick={handleContinueAsGuest}>
          Continue as a guest
        </Button>
      </div>
    );
  };

  return (
    <BaseLayout>
      <div className={styles.container}>
        <div className={styles.content}>
          <Tabs tabs={tabs} setNewTab={setNewTab} activeTab={tab} />
          {tabs[tab].content({
            hasError,
            step,
            // accountId,
            password,
            validationError,
            // onAccountIdChange: setAccountId,
            onPasswordChange: setPassword,
            onCheckScannedCard: handleCheckScannedCard,
            onClearError: handleClearError,
            onClickContinue: handleContinue,
          })}
          {renderButtons()}
        </div>
      </div>
      {isPhoneModalOpen && (
        <PhoneModal
          onConfirm={handleConfirmPhone}
          onClose={handleClosePhoneModal}
          validationError={validationError}
        />
      )}
    </BaseLayout>
  );
};

export default React.memo(SignIn);
