import React, { useEffect, useRef, useState } from 'react';
import { SimpleKeyboard as ISimpleKeyboard } from 'react-simple-keyboard/build/interfaces';

import styles from './Keyboard.module.scss';

import SimpleKeyboard from 'react-simple-keyboard';

export type PropsType = {
  onChangeKeyInput(value: string): void;
  inputName?: string;
  onRender?(keyboard?: ISimpleKeyboard | undefined): void;
  value: string;
  onClickContinue(): void;
};

type LayoutNameType = 'default' | 'shift' | 'alt';

const layouts: Record<LayoutNameType, string[]> = {
  default: [
    'q w e r t y u i o p {bksp}',
    'a s d f g h j k l {enter}',
    '{shift} z x c v b n m , . {shift}',
    '{alt} {space} .com @',
  ],
  shift: [
    'Q W E R T Y U I O P {bksp}',
    'A S D F G H J K L {enter}',
    '{shiftactivated} Z X C V B N M , . {shiftactivated}',
    '{alt} {space} .com @',
  ],
  alt: [
    '1 2 3 4 5 6 7 8 9 0 {bksp}',
    `@ # $ & * ( ) ' " {enter}`,
    '{shift} % - + = / ; : ! ? {shift}',
    '{default} {space} .com @',
  ],
};

const layoutDisplay = {
  '{alt}': '.?123',
  '{shift}': '⇧',
  '{shiftactivated}': '⇧',
  '{enter}': 'continue',
  '{bksp}': '⌫',
  '{space}': '  ',
  '{default}': 'ABC',
  '{back}': '⇦',
};

const Keyboard: React.FC<PropsType> = ({
  onChangeKeyInput,
  inputName,
  onRender,
  value,
  onClickContinue,
}) => {
  const [layoutName, setLayoutName] = useState<LayoutNameType>('default');
  const keyboard = useRef<ISimpleKeyboard>();

  useEffect(() => {
    if (keyboard?.current) {
      keyboard.current?.setInput(value || '');
    }
  }, []);

  const getLayoutByButton = (button: string): LayoutNameType => {
    switch (button) {
      case '{shift}':
      case '{shiftactivated}':
      case '{default}':
        return layoutName === 'default' ? 'shift' : 'default';
      case '{alt}':
        return layoutName === 'alt' ? 'default' : 'alt';
      default:
        return 'default';
    }
  };

  const handleKeyPress = (button: string) => {
    if (button === '{enter}') {
      onClickContinue();
    }
    if (button.includes('{') && button.includes('}')) {
      const layout = getLayoutByButton(button);

      setLayoutName(layout);
    }
  };

  return (
    <SimpleKeyboard
      onChange={onChangeKeyInput}
      onKeyPress={handleKeyPress}
      baseClass={styles.myTheme}
      inputName={inputName}
      onRender={onRender}
      layoutName={layoutName}
      layout={layouts}
      display={layoutDisplay}
      disableCaretPositioning
      keyboardRef={(r) => (keyboard.current = r)}
    />
  );
};

export default React.memo(Keyboard);
