import React from 'react';

import styles from './OptionsList.module.scss';
import { IProductOptionsGroupView } from '@interfaces/ProductOption';

import OptionsSelect from '../OptionsSelect/OptionsSelect';

type PropsType = {
  optionsGroup: IProductOptionsGroupView;
  selected: Record<string, number | string>;
  onChange: (group: string, option: number | string) => void;
};

const OptionsList: React.FC<PropsType> = ({ optionsGroup, onChange, selected }) => {
  return (
    <div className={styles.container}>
      <h6 className={styles.title}>{optionsGroup.description}</h6>
      <OptionsSelect
        options={optionsGroup.items}
        value={selected[optionsGroup.id]}
        onChange={(option) => onChange(optionsGroup.id, option)}
      />
    </div>
  );
};

export default React.memo(OptionsList);
