import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import appReducer from './slices/app/app.slice';
import categoriesReducer from './slices/categories/categories.slice';
import shopReducer from './slices/shop/shop.slice';
import authReducer from './slices/auth/auth.slice';
import productsReducer from './slices/products/products.slice';
import orderReducer from './slices/order/order.slice';
import memberReducer from './slices/member/member.slice';
import paymentReducer from './slices/payment/payment.slice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    shop: shopReducer,
    categories: categoriesReducer,
    auth: authReducer,
    products: productsReducer,
    order: orderReducer,
    member: memberReducer,
    payment: paymentReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
