import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Home.module.scss';

import lemonLabel from '@assets/happy-lemon-label.png';
import image1 from '@assets/banner.png';
import image2 from '@assets/welcome-image-2.png';
import image3 from '@assets/welcome-image-3.png';
import image4 from '@assets/welcome-image-4.png';
import errorImage from '@assets/error.jpg';

import { BaseLayout } from '@components/Layout';
import { Carousel } from '@components/Common';
import { useAppSelector } from '@hooks/redux';
import { isShopVerifiedFailedSelector } from '@store/slices/shop/shop.selectors';

import env from '@config/env';

const images = [image1, image2, image3, image4];

const Home = () => {
  const navigate = useNavigate();

  const isVerifiedFailed = useAppSelector(isShopVerifiedFailedSelector);

  const handleNavigate = () => {
    navigate(`/product-list`);
  };

  return (
    <BaseLayout>
      <div className={styles.container}>
        <Carousel images={images} />
        <div className={styles.tapToOrderPanel} onClick={handleNavigate}>
          <h1>TAP</h1>
          <button>TO ORDER</button>
          <img src={lemonLabel} alt="happy lemon label" />
          <span className={styles.version}>
            v{env.VERSION}{env.COUNT && ` (${env.COUNT || 0}) `}{' '}
            <span className={styles.environment}>{env.ENV !== 'prod' ? 'test' : ''}</span>
          </span>
        </div>
      </div>
      {isVerifiedFailed && (
        <div className={styles.errorModal}>
          <img src={errorImage} alt="error" />
        </div>
      )}
    </BaseLayout>
  );
};

export default React.memo(Home);
