import { createSlice } from '@reduxjs/toolkit';

import { validateShop } from '@store/slices/shop/shop.thunk';

interface IState {
  isVerified: boolean;
  isVerifiedFailed: boolean;
  pricelevelID: null | number;
  regionID: null | number;
  id: null | number | string;
  apiToken: string | undefined
  deviceId: string
  posId: string
  cloverIP: string
  transactionIP: string
}

const initialState: IState = {
  isVerifiedFailed: false,
  isVerified: false,
  pricelevelID: null,
  regionID: null,
  id: null,
  apiToken: '',
  deviceId: '',
  posId: '',
  cloverIP: '',
  transactionIP: ''
};

const { reducer } = createSlice({
  name: 'shop',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(validateShop.fulfilled, (state, action) => {
      const {
        pricelevel_kiosk,
        region_id,
        id,
        clover_api_token
      } = action.payload;
      state.pricelevelID = pricelevel_kiosk;
      state.regionID = region_id;
      state.id = id;
      state.isVerifiedFailed = false;
      state.isVerified = true;
      state.apiToken = clover_api_token
    });
    builder.addCase(validateShop.rejected, (state) => {
      state.isVerified = false;
      state.isVerifiedFailed = true;
    });
  },
});

export default reducer;
