import React, { useMemo } from 'react';

import styles from './ToppingsList.module.scss';
import { ITopping, IToppingGroupView } from '@interfaces/Topping';
import { IOrderTopping } from '@interfaces/Order';

import ToppingSelect from '../ToppingSelect/ToppingSelect';
import { getOrderToppingsUsage } from '@store/slices/order/order.utils';

type PropsType = {
  toppingsGroup: IToppingGroupView;
  selected?: IOrderTopping[];
  onChange: (toppingId: number, groupId: string, quantity: number) => void;
  onDelete: (toppingId: number) => void;
};

const ToppingsList: React.FC<PropsType> = ({ toppingsGroup, selected, onDelete, onChange }) => {
  const selectedGroupToppings = useMemo(() => {
    return selected?.filter((s) => s.groupId === toppingsGroup.id) || [];
  }, [selected]);

  const usedGroupLimit = useMemo(() => {
    return getOrderToppingsUsage(selectedGroupToppings);
  }, [selectedGroupToppings]);

  const renderTopping = (topping: ITopping) => {
    const selected = selectedGroupToppings.find((t) => t.toppingId === topping.id);
    const isLimitExceeded = usedGroupLimit >= toppingsGroup.maximumUsage;
    const groupLimitLeft = toppingsGroup.maximumUsage - usedGroupLimit;
    const maxGroupLimit = selected ? selected.quantity + groupLimitLeft : groupLimitLeft;
    const maxQuantity = Math.min(maxGroupLimit, topping.maximumUsage);

    return (
      <ToppingSelect
        groupId={toppingsGroup.id}
        key={topping.id}
        topping={topping}
        selected={selected}
        maxQuantity={maxQuantity}
        onChange={onChange}
        onDelete={onDelete}
        disabled={!selected && isLimitExceeded}
      />
    );
  };

  return (
    <div className={styles.container}>
      <h6 className={styles.title}>
        {toppingsGroup.description} <span>(optional)</span>
      </h6>

      <div>{toppingsGroup.toppings.map(renderTopping)}</div>
    </div>
  );
};

export default React.memo(ToppingsList);
