import React from 'react';
import classNames from 'classnames';

import styles from './SideModalLayout.module.scss';
import closeSVG from '@assets/close.svg';
import { useNoScroll } from '@hooks/useNoScroll';

type PropsType = {
  width?: number;
  title: string;
  className?: string;
  bodyClassName?: string;
  onClose?: () => void;
};

const SideModalLayout: React.FC<React.PropsWithChildren<PropsType>> = ({
  children,
  width = 400,
  title,
  className,
  bodyClassName,
  onClose = () => undefined,
}) => {
  useNoScroll();

  const handleOverlayClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <>
      <div className={classNames(styles.container, className)} style={{ width, height: '100%' }}>
        <div className={styles.heading}>
          <h4 className={styles.title}>{title}</h4>
          <img className={styles.closeIcon} src={closeSVG} alt="Close" onClick={onClose} />
        </div>
        <div className={classNames(styles.body, bodyClassName)}>{children}</div>
      </div>
      <div className={styles.overlay} onClick={handleOverlayClick} />
    </>
  );
};
export default React.memo(SideModalLayout);
