import axios, { AxiosInstance } from 'axios';

import env from '@config/env';
import endpoints from '@config/endpoints';

const api = axios.create({
  baseURL: env.MEMBERSHIP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Basic ${env.MEMBERSHIP_API_TOKEN}`,
  },
});

export class MembershipBaseApi {
  public api: AxiosInstance = api;
  public endpoints = endpoints.MEMBERSHIP;
}
