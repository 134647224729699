import React from 'react';

import styles from './PaymentLayout.module.scss';

type PropsType = {
  children: React.ReactNode;
};

const PaymentLayout: React.FC<PropsType> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

export default PaymentLayout;
