import { FC } from 'react';
import { CloseButton } from '..';

import styles from './PromoCard.module.scss';
import { IOrderPromo } from '@interfaces/Promotions';

export enum PromoCardEnum {
  checkout = 'checkout',
  menu = 'menu',
}

interface PropsType {
  type: PromoCardEnum;
  promo?: IOrderPromo;
}

const PromoCard: FC<PropsType> = ({ type, promo }) => {
  return (
    <div className={styles.promo}>
      {type === PromoCardEnum.menu && (
        <div className={styles.promo__header}>
          <CloseButton />
        </div>
      )}
      <div className={styles.promo__body}>
        <p className={styles.promo__title}>Promotion</p>
        <p className={styles.promo__discount}>
          {promo?.type === 1 && promo.value
            ? `-$${promo?.value * promo.quantity}`
            : `-%${promo?.value}`}
        </p>
      </div>
      <p className={styles.promo__footer}>{promo?.description}</p>
      {type === PromoCardEnum.checkout && <button className={styles.promo__btn}>Remove</button>}
    </div>
  );
};

export default PromoCard;
