import React, { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { validateShop } from '@store/slices/shop/shop.thunk';
import { isInitializedSelector } from '@store/slices/app/app.selectors';
import { getProductsData } from '@store/slices/products/products.thunk';
import { removePoductsDataFromLocalStorage } from '@store/slices/products/products.utils';
import { getShopDataFromUrl } from '@utils/getShopDataFromUrl';

import { Router } from '@components/App';

const App = () => {
  const dispatch = useAppDispatch();
  // const location = useLocation();
  const queryString = window.location.search.substring(1);
  const params = queryString.split('&');
  const { code, deviceID, id } = getShopDataFromUrl(params);

  // const queryParams = new URLSearchParams(location.search);
  // const encryptionCode = queryParams.get('code');
  // const shopID = queryParams.get('id');
  // const deviceID = queryParams.get('deviceID');

  const isInitialized = useAppSelector(isInitializedSelector);

  useEffect(() => {
    const timer = setInterval(() => {
      removePoductsDataFromLocalStorage();
      dispatch(getProductsData());
    }, 600000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    dispatch(validateShop({ encryptionCode: code, shopID: id, deviceID }));
  }, []);

  if (!isInitialized) {
    return null;
  }

  return <Router />;
};

export default App;
