import { createSlice } from '@reduxjs/toolkit';
import {
  cancelPayment,
  checkPaymentMethods,
  startPayment,
  startPaymentWithMember,
} from '@store/slices/payment/payment.thunks';

interface IState {
  isPaymentMethodsEnabled: boolean;
  isPaymentInProgress: boolean;
  timeToPay: number;
  isPaymentSuccess: null | boolean;
}

const initialState: IState = {
  isPaymentMethodsEnabled: false,
  isPaymentInProgress: false,
  timeToPay: 60,
  isPaymentSuccess: null,
};

const { reducer, actions } = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    clearIsSaccess(state) {
      state.isPaymentSuccess = initialState.isPaymentSuccess
    }
  },
  extraReducers(builder) {
    builder.addCase(checkPaymentMethods.fulfilled, (state) => {
      state.isPaymentMethodsEnabled = true;
    });

    builder.addCase(checkPaymentMethods.rejected, (state) => {
      state.isPaymentMethodsEnabled = false;
    });

    builder.addCase(startPayment.pending, (state) => {
      state.isPaymentInProgress = true;
      state.isPaymentSuccess = null;
    });

    builder.addCase(startPayment.fulfilled, (state) => {
      state.isPaymentSuccess = true
    });

    builder.addCase(startPayment.rejected, (state, { error }) => {
      // eslint-disable-next-line no-console
      console.log(error);
      state.isPaymentInProgress = false;
      state.isPaymentSuccess = false;
    });
    builder.addCase(startPaymentWithMember.pending, (state) => {
      state.isPaymentInProgress = true;
      state.isPaymentSuccess = null;
    });

    builder.addCase(startPaymentWithMember.fulfilled, (state) => {
      state.isPaymentSuccess = true
    });

    builder.addCase(startPaymentWithMember.rejected, (state, { error }) => {
      // eslint-disable-next-line no-console
      console.log(error);
      state.isPaymentInProgress = false;
      state.isPaymentSuccess = false;
    });

    builder.addCase(cancelPayment.fulfilled, (state) => {
      state.isPaymentInProgress = false;
    });
  },
});

export const {
  clearIsSaccess
} = actions;

export default reducer;
