import React, { useEffect } from 'react';
import styles from './Toast.module.scss';

type PropsType = {
  title: string;
  subtitle?: string;
  onClose(): void;
  ms?: number;
};

const Toast: React.FC<PropsType> = ({ title, subtitle, onClose, ms = 3500 }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, ms);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.toast}>
        <h6>{title}</h6>
        <p>{subtitle}</p>
      </div>
    </div>
  );
};

export default React.memo(Toast);
