import { IProductOption, IProductOptionsGroup } from '@interfaces/ProductOption';
import { CustomOptionsEnum, ProductVariantsEnum, TemperatureEnum } from '@interfaces/enums';

export const customOptionsGroups: Record<CustomOptionsEnum, IProductOptionsGroup> = {
  [CustomOptionsEnum.Temperature]: {
    id: CustomOptionsEnum.Temperature,
    description: 'Ice/Hot',
    items: [TemperatureEnum.Cold, TemperatureEnum.Hot],
  },
  [CustomOptionsEnum.Size]: {
    id: CustomOptionsEnum.Size,
    description: 'Cup Size',
    items: [],
  },
};

export const customOptions: IProductOption[] = [
  {
    id: TemperatureEnum.Cold,
    description: 'Cold',
    code: TemperatureEnum.Cold,
  },
  {
    id: TemperatureEnum.Hot,
    description: 'Hot',
    code: TemperatureEnum.Hot,
  },
  {
    id: ProductVariantsEnum.Small,
    description: 'Small',
    code: ProductVariantsEnum.Small,
  },
  {
    id: ProductVariantsEnum.Regular,
    description: 'Regular',
    code: ProductVariantsEnum.Regular,
  },
  {
    id: ProductVariantsEnum.Large,
    description: 'Large',
    code: ProductVariantsEnum.Large,
  },
];
