import React from 'react';

import styles from './PaymentBody.module.scss';

type PropsType = {
  countdown: number;
  isPaymentInProgress?: boolean;
  isPaymentSuccess?: null | boolean;
  total: number;
};

const PaymentBody: React.FC<PropsType> = ({ countdown, isPaymentSuccess, total }) => {
  return (
    <div className={styles.container}>
      {isPaymentSuccess === null && (
        <>
          {total === 0 ? (
            <h5>Processing</h5>
          ) : (
            <>
              <h5>Tap or Insert to Pay</h5>
              <p className={styles.countdown}>{countdown}</p>
            </>
          )}
        </>
      )}

      {isPaymentSuccess && (
        <>
          <h5>Successfully!</h5>
          <p className={styles.sub_title}>Thanks for your order</p>
        </>
      )}

      {isPaymentSuccess === false && (
        <h5 className={styles.error}>
          Something went wrong during the payment process. Please review your payment method and
          attempt again
        </h5>
      )}
    </div>
  );
};

export default React.memo(PaymentBody);
