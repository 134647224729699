import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from './Carousel.module.scss';

type PropsType = {
  images: string[];
  interval?: number;
};

const Carousel: React.FC<PropsType> = ({ images, interval = 2000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalRef = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % images.length);
    }, interval);

    return () => clearInterval(intervalRef);
  }, [currentIndex]);

  return (
    <div className={styles.carousel}>
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          className={classNames(index === currentIndex && styles.active)}
          alt={`screen-${index}`}
        />
      ))}
    </div>
  );
};

export default React.memo(Carousel);
