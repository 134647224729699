import { FC } from 'react';
import PromotionsList from '../PromotionsList/PromotionsList';

import styles from './Promotions.module.scss';
import { IProduct } from '@interfaces/Product';
import PromotionsProductList from '../PromotionsProductList/PromotionsProductList';
import { IPromotion } from '@interfaces/Promotions';

interface PropsType {
  products: Record<string, IProduct[]>,
  promo: IPromotion[];
  onSelectProduct: (id: number, categoryId: number | undefined) => void;
  selectedPromotion: {id: number, title: string}
  onSelectPromo: (id: number, title: string) => void;
  onClearPromo: () => void;
}

const Promotions: FC<PropsType> = ({ products, promo, onSelectProduct, selectedPromotion, onSelectPromo, onClearPromo }) => {
  

  return (
    <div className={styles.container}>
      <p className={styles.info}>Tap on the Card</p>
      {!selectedPromotion.id ? (
        <PromotionsList promo={promo} onSelectPromo={onSelectPromo} />
      ) : (
        <PromotionsProductList
          products={products}
          onSelectProduct={onSelectProduct}
          title={selectedPromotion.title}
          onClear={onClearPromo}
        />
      )}
    </div>
  );
};

export default Promotions;
