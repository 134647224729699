import { FC, useState } from 'react';
import styles from './PromotionButton.module.scss';

import promotionIcon from '@assets/promotion-img.png';

interface PropsType {
  onOpen: () => void;
}

const PromotionButton: FC<PropsType> = ({ onOpen }) => {
  const [visible, setVisible] = useState(true);

	const handleClick = () => {
    onOpen()
		setVisible(false)
	}

  return (
    <div className={styles.container}>
      <div className={`${styles.text} ${!visible ? styles.hide : ''}`} >Promo available <div className={styles.triangle}/></div>
      <div className={styles.wrapper} onClick={handleClick}>
        <img src={promotionIcon} alt="" />
      </div>
    </div>
  );
};

export default PromotionButton;
