import React, { ChangeEvent, useRef, useState } from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';

import styles from './Input.module.scss';
import 'react-phone-input-2/lib/style.css';
import { addUSCountryCode } from '@utils/addUSCountryCode';
import eyeIcon from '@assets/eyeIcon.png';
import hideIcon from '@assets/hidePasswordIcon.png';

import SimpleKeyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

import PhoneInput from 'react-phone-input-2';

export type PropsType = {
  value: string;
  placeholder?: string;
  label?: string;
  errorText?: string;
  invalid?: boolean;
  type?: 'text' | 'phone' | 'password';
  onChange?(e: ChangeEvent<HTMLInputElement>, value: string): void;
  name?: string;
  readOnly?: boolean;
  id?: string;
  className?: string;
  maxLength?: number;
  onChangeKeyInput?(value: string): void;
};

const Input: React.FC<PropsType> = ({
  value,
  label,
  invalid = false,
  type,
  placeholder = 'Phone or Email',
  errorText = '',
  onChange,
  name,
  readOnly = true,
  id = '',
  className,
  maxLength,
  onChangeKeyInput = () => {},
}) => {
  const [show, setShow] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [keyboardOpen, setKeyboardOpen] = useState(false);

  const onClick = () => {
    setShow((prevState) => !prevState);
  };

  const handlePhoneNumberChange = (
    value: string,
    _: any,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (onChange) {
      onChange(event, value);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      const value = e.target.value;

      onChange(e, value);
    }
  };

  const debounseCloseModal = debounce(() => {
    setKeyboardOpen(false);
  }, 2500);

  return (
    <div
      id={id}
      className={classNames([styles.container, invalid && styles.validation], className)}
    >
      {label && <h5>{label}</h5>}
      <div className={styles.inputContainer}>
        {type === 'phone' && (
          <PhoneInput
            value={addUSCountryCode(value)}
            placeholder={placeholder}
            onChange={handlePhoneNumberChange}
            country="us"
            onlyCountries={['us']}
            inputClass={classNames(styles.input, styles.input_phone)}
            inputProps={{ readOnly, name }}
            countryCodeEditable={false}
            disableDropdown
          />
        )}

        {type !== 'phone' && (
          <input
            ref={inputRef}
            onFocus={() => {
              if (!inputRef.current) return;

              if (inputRef.current.name === 'pin') {
                onChangeKeyInput('');
                setKeyboardOpen(true);
              }
            }}
            value={value}
            readOnly={readOnly}
            placeholder={placeholder}
            type={show ? 'text' : type}
            onChange={handleInputChange}
            name={name}
            className={styles.input}
            maxLength={maxLength}
          />
        )}

        <p className={styles.error}>{errorText}</p>

        {type === 'password' && (
          <img
            className={styles.showPassword}
            src={show ? hideIcon : eyeIcon}
            alt="show password"
            onClick={onClick}
          />
        )}

        {keyboardOpen && (
          <div className={styles.inputKeyboard}>
            <SimpleKeyboard
              inputName={name}
              value={value}
              onChange={(value) => {
                onChangeKeyInput(value);
                if (value.length === 4) debounseCloseModal();
              }}
              layout={{
                default: ['1 2 3', '4 5 6', '7 8 9', '0 {bksp}'],
              }}
              display={{
                '{bksp}': '<',
              }}
            />
            <button onClick={() => setKeyboardOpen(false)}>X</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Input);
