class LocaleStorage {
  set(key: string, data: any = '') {
    localStorage.setItem(key, JSON.stringify(data));
  }

  get<T = any>(key: string) {
    const json = localStorage.getItem(key);

    if (!json) {
      return null;
    }

    return JSON.parse(json) as T;
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }
}

export default new LocaleStorage();
