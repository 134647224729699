import React, { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import styles from './SelectProductModal.module.scss';
import { IOrder, IOrderTopping } from '@interfaces/Order';
import { calculateOrderTotal, getOrderView } from '@store/slices/order/order.utils';
import {
  filterCustomOptions,
  getProductCustomOptionsFromVariant,
  getProductVariantFromOptions,
} from '@store/slices/products/products.utils';
import { MAX_PRODUCTS_PER_ORDER } from '@config/constants';
import { SelectProductModalPropsType } from '@hooks/useSelectProductModal';

import { SideModalLayout } from '@components/Layout';
import ProductSummary from '../ProductSummary/ProductSummary';
import ToppingsList from '../ToppingsList/ToppingsList';
import OptionsList from '../OptionsList/OptionsList';
import { Button, Counter } from '@components/Common';
import { useAppDispatch } from '@hooks/redux';
import { checkIfPromoIsAvailable } from '@store/slices/order/order.slice';

const SelectProductModal: React.FC<SelectProductModalPropsType> = ({
  categoryId,
  product,
  toppings = [],
  options = {},
  customOptions = [],
  onClose = () => undefined,
  onAddOrder,
  onUpdateOrder,
  rowData,
  initialOrder,
  btnLabel = 'Add',
}) => {
  const dispatch = useAppDispatch()

  const [selectedOptions, setSelectedOptions] = useState<Record<string, number | string>>(
    initialOrder?.options || {}
  );

  const [customSelectedOptions, setCustomSelectedOptions] = useState<
    Record<string, string | number>
  >(() => {
    return initialOrder ? getProductCustomOptionsFromVariant(initialOrder.productVariant) : {};
  });

  const [selectedToppings, setSelectedToppings] = useState<IOrderTopping[]>(
    initialOrder?.toppings || []
  );

  const [quantity, setQuantity] = useState(initialOrder?.quantity || 1);

  const order = useMemo<IOrder | null>(() => {
    const category = initialOrder?.categoryId || categoryId;

    if (!product || category === undefined) {
      return null;
    }

    return {
      id: initialOrder?.id || uuidv4(),
      categoryId: category,
      productId: product.id,
      productVariant: getProductVariantFromOptions(customSelectedOptions),
      toppings: selectedToppings,
      options: selectedOptions,
      quantity,
    };
  }, [
    product,
    selectedOptions,
    customSelectedOptions,
    selectedToppings,
    quantity,
    categoryId,
    initialOrder,
  ]);

  const orderView = useMemo(() => {
    if (!order || !product) {
      return null;
    }

    return getOrderView(order, product, rowData.toppings, rowData.options, rowData.productOptions);
  }, [order, toppings]);

  const optionsToRender = useMemo(() => {
    return order ? options[order.productVariant] || [] : [];
  }, [options, order]);

  const customOptionsToRender = useMemo(() => {
    return filterCustomOptions(customOptions, customSelectedOptions);
  }, [customOptions, customSelectedOptions]);

  const total = useMemo(() => {
    if (!orderView) {
      return 0;
    }

    return calculateOrderTotal(orderView);
  }, [orderView]);

  const handleChangeOption = (group: string, option: number | string) => {
    setSelectedOptions((prev) => ({ ...prev, [group]: option }));
  };

  const handleChangeCustomOption = (group: string, option: string | number) => {
    setCustomSelectedOptions((prev) => ({ ...prev, [group]: option }));
  };

  const updateToppingQuantity = (topping: IOrderTopping, quantity: number) => {
    return {
      ...topping,
      quantity,
    };
  };

  const handleChangeTopping = (toppingId: number, groupId: string, quantity: number) => {
    setSelectedToppings((prev) => {
      const isExist = prev.some((t) => t.toppingId === toppingId);

      if (isExist) {
        return prev.map((t) => {
          if (t.toppingId === toppingId) {
            return updateToppingQuantity(t, quantity);
          }

          return t;
        });
      } else {
        return [...prev, { toppingId, quantity, groupId }];
      }
    });
  };

  const handleDeleteTopping = (toppingId: number) => {
    setSelectedToppings((prev) => prev.filter((t) => t.toppingId !== toppingId));
  };

  const handleAddOrder = () => {
    if (initialOrder && onUpdateOrder && order) {
      onUpdateOrder(order);
    } else if (onAddOrder && order) {
      onAddOrder(order);
    }
    dispatch(checkIfPromoIsAvailable())
  };

  if (!order || !orderView || !product) {
    return null;
  }

  return (
    <SideModalLayout
      title="Select Options"
      onClose={onClose}
      className={styles.modal}
      bodyClassName={styles.body}
    >
      <div className={styles.content}>
        <ProductSummary product={orderView.product} className={styles.product} />

        {customOptionsToRender.map((group) => (
          <OptionsList
            key={group.description}
            optionsGroup={group}
            onChange={handleChangeCustomOption}
            selected={customSelectedOptions}
          />
        ))}

        {optionsToRender.map((group) => (
          <OptionsList
            key={group.description}
            optionsGroup={group}
            onChange={handleChangeOption}
            selected={selectedOptions}
          />
        ))}

        {toppings.map((group) => (
          <ToppingsList
            key={group.description}
            toppingsGroup={group}
            selected={selectedToppings}
            onChange={handleChangeTopping}
            onDelete={handleDeleteTopping}
          />
        ))}
      </div>

      <div className={styles.footer}>
        <Counter
          variant="secondary"
          className={styles.counter}
          value={quantity}
          onChange={setQuantity}
          min={1}
          max={MAX_PRODUCTS_PER_ORDER}
        />
        <Button className={styles.addBtn} onClick={handleAddOrder}>
          <span>{btnLabel}</span>
          <span>${total.toFixed(2)}</span>
        </Button>
      </div>
    </SideModalLayout>
  );
};

export default React.memo(SelectProductModal);
